import { RemoveRedEye, VisibilityOff } from '@mui/icons-material';
import { CircularProgress, IconButton, TextareaAutosize } from '@mui/material';
import React, { useState } from 'react'

export default function Input(
    {
        type, 
        name, 
        placeholder,
        placeholderText,
        required, 
        variant, 
        value, 
        onChange,
        label,
        labelText,
        description,
        descriptionText,
        autoFocus,
        icon,
        readOnly,
        style
    }
) {
    const [ inputType, setInputType ] = useState(type);

    const changePasswordType = () => {
        if (inputType === "password") {
            setInputType("text");
        } else {
            setInputType("password");
        }
    }

    return (
        <div className='input-layout' style={style}>
            {label && <label htmlFor={name}>{labelText}</label>}
            <div className="input-body">
                {type === "text-area" ?
                    <TextareaAutosize 
                        maxRows={4}
                        className={`input variant-${variant} ${type} ${icon && 'with-icon'}`}
                        id={name}
                        name={name}
                        minRows={4}
                        value={value}
                        placeholder={placeholder && placeholderText} 
                        required={required}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                    />
                    :
                    <input 
                        className={`input variant-${variant} ${type} ${icon && 'with-icon'}`}
                        id={name}
                        name={name}
                        type={inputType}
                        value={value}
                        placeholder={placeholder && placeholderText} 
                        required={required}
                        onChange={onChange}
                        autoFocus={autoFocus}
                        readOnly={readOnly}
                    />
                }
                {icon}
                {type === "password" &&
                    <IconButton
                        className='IconButton'
                        onClick={changePasswordType}
                    >
                        {inputType === "password" && <VisibilityOff />}
                        {inputType === "text" && <RemoveRedEye />}
                    </IconButton>
                }
                {description && 
                <span style={{fontSize: '.9rem'}} className={`font-style flex flow-row gap-small align-center color-${description}`}>
                    {description === "loading" && <CircularProgress className='progress' />}
                    {descriptionText}
                </span>
                }
            </div>
        </div>
    )
}
