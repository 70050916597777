import { Outlet, createBrowserRouter } from "react-router-dom";
import Home from "../../view/home/Home";
import Upload from "../../view/upload/Upload";
import Login from "../../view";
import Layout from "../../view/Layout";
import Search from "../../view/search";
import Anime from "../../view/anime";
import AnimeHome from "../../view/anime/Home";
import AnimeInfo from "../../view/anime/info";
import Visual from "../../view/anime/info/Visual";
import Basic from "../../view/anime/info/Basic";
import Seasons from "../../view/anime/Seasons";
import Banner from "../../view/home/Banner";
import Sections from "../../view/home/Sections";

const AoRouter = createBrowserRouter([
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/home",
                element: <Home />,
                children: [
                    {
                        path: "banner",
                        element: <Banner />
                    },
                    {
                        path: "sections",
                        element: <Sections />
                    }
                ]
            },
            {
                path: "/search",
                element: <Search />,
                children: [
                    {
                        path: ":search",
                        element: <Search />
                    }
                ]
            },
            {
                path: "/upload",
                element: <Upload />
            },
            {
                path: "/anime/:id",
                element: <Anime />,
                children: [
                    {
                        index: true,
                        element: <AnimeHome />
                    },
                    {
                        path: "info",
                        element: <AnimeInfo />,
                        children: [
                            {
                                path: "visual",
                                element: <Visual />
                            },
                            {
                                path: "basic",
                                element: <Basic />
                            }
                        ]
                    },
                    {
                        path: "seasons",
                        element: <Seasons />
                    }
                ]
            }
        ]
    }
])

export default AoRouter;