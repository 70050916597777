import React, { useEffect, useState } from 'react'
import Input from '../../components/form/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material';
import SearchIcon from '../../assets/images/Search';
import Results from './Results';
import SEO from '../../services/seo/seo';
import './index.scss';

export default function Search() {
    const navigate = useNavigate();
    const params = useParams();
    const [ title, setTitle ] = useState('Buscar');
    const [ searchTerm, setSearchTerm ] = useState(params.search || "");
    const [ searching, setSearching ] = useState(false);

    useEffect(() => {
        setSearching(true);
        if (searchTerm === "" || !searchTerm) {
            navigate('/search');
            setSearching(false);
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            navigate(`/search/${encodeURIComponent(searchTerm)}`);
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm]);

    useEffect(() => {
        if (params.search) {
            setTitle(`Buscar "${params.search}"`);
        } else {
            setTitle("Buscar");
        }
    }, [params.search]);

    return (
        <>
            <SEO title={title} />
            <div className='search'>
                <div className="search__input-layout">
                    <Input 
                        type="text"
                        value={searchTerm}
                        placeholder
                        placeholderText="Busca lo que necesitas..."
                        variant="search"
                        onChange={(e) => setSearchTerm(e.target.value)}
                        icon={searching ? <CircularProgress className='input-icon' /> : <SearchIcon className="input-icon" />}
                        autoFocus
                        style={{ 
                            border: '0'
                        }}
                    />
                </div>
                {searchTerm &&
                    <Results
                        searchTerm={searchTerm}
                        searching={searching}
                        setSearching={setSearching}
                    />
                }
            </div>
        </>
    )
}
