import React from 'react';
import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import './Modals.scss';

export default function Modal({ 
    title, 
    variant, 
    close,
    children, 
    visible, 
    modals,
    loading,
    style
  }) {
  return (
    <div className={`modal ${visible} ${loading && 'loading'}`} style={style}>
      <div className="backdrop true" onClick={close}></div>
      <div className={`modal-layout ${variant}`}>
        {title &&
          <div className="modal-title">
            <h3>{title}</h3>
            <IconButton
                className='IconButton' 
                onClick={close}
            >
              <Close />
            </IconButton>
          </div>
        }
        <div className="modal-body">
          {children}
        </div>
      </div>
      {modals}
    </div>
  )
}
