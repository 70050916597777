import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "./context/auth/AuthContext";
import { ContentListsProvider } from "./context/ContentLists";
import { ModalsProvider } from "./context/Modals";

const queryClient = new QueryClient()

function App({ children }) {
    return(
        <QueryClientProvider client={queryClient}>
            <ContentListsProvider>
                <AuthProvider>
                    <ModalsProvider>
                        {children}
                    </ModalsProvider>
                </AuthProvider>
            </ContentListsProvider>
        </QueryClientProvider>
    )
}

export default App;