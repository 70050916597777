import React, { useContext, useState } from 'react'
import { ContentListsContext } from '../../context/ContentLists'
import { useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import SEO from '../../services/seo/seo';
import { AuthContext } from '../../context/auth/AuthContext';

export default function AnimeHome() {
    const params = useParams();
    const { currentAnime } = useContext(ContentListsContext);
    const { lang } = useContext(AuthContext);

    const [ sendingDiscord, setSendingDiscord ] = useState(false);

    const shareDiscord = () => {
        setSendingDiscord(true);
        let n = 182;
        let url = `https://aodesu.com/anime/${currentAnime.id}`;
        let title;
        let seeMore;

        if (lang === 'en') {
            title = "New anime available!"
            seeMore = "Watch now!"
        }
        if (lang === 'es') {
            title = "¡Nuevo anime disponible!"
            seeMore = "¡Ver ahora!"
        }

        const content = {
            "embeds": [{
                "title": title,
                "description": `## ${currentAnime.name} \n \n  ${currentAnime.synopsis.length > n ? currentAnime.synopsis.slice(0, n-1) + "..." : currentAnime.synopsis} \n \n  **[${seeMore}](${url})**`,
                "color": 0x4169e1,
                "image": {
                    "url": `${currentAnime.images.wide}`,
                },
                "url": url
            }],
        }

        let webhookUrl;

        if (lang === 'en') webhookUrl = process.env.REACT_APP_DISCORD_NEW_ANIME_WEBHOOK_EN;
        if (lang === 'es') webhookUrl = process.env.REACT_APP_DISCORD_NEW_ANIME_WEBHOOK_ES;

        fetch(webhookUrl, {
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            setSendingDiscord("success")
        })
    }

    return (
        <>
            <SEO title={currentAnime.name} />
            <div>
                <div className="section">
                    <h2>Compartir</h2>
                    <div className="flex flow-row">
                        <Button onClick={shareDiscord} className={`button variant-4 flex gap-normal ${sendingDiscord === "success" && 'success'} ${sendingDiscord === true && 'loading'}`}>
                            <div className="button-icon">
                                <svg className='svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
                                    <g id="图层_2" data-name="图层 2">
                                        <g id="Discord_Logos" data-name="Discord Logos">
                                            <g id="Discord_Logo_-_Large_-_White" data-name="Discord Logo">
                                                <path className="cls-1" d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="button-title">Compartir por Discord</div>
                            <div className="loading">
                                <CircularProgress className='progress' />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}
