import { CheckRounded, CloseRounded } from '@mui/icons-material'
import React, { useContext, useEffect } from 'react'
import { ModalsContext } from '../../context/Modals';

export default function Preview({ name, synopsis, genres, tags}) {
    const { selectImageWidePreview, selectImageTallPreview } = useContext(ModalsContext);

    return (
        <div className='preview-container'>
            <h2>Vista previa</h2>
            <div className="data-img" style={{ backgroundImage: `url("${selectImageWidePreview}")`}}>
                {!selectImageTallPreview ?
                <div className="img"></div>
                :
                <img className='img' src={selectImageTallPreview} alt="" />
                }
            </div>
            <div className="basic-info">
                <div>
                    <div className="preview-txt">
                        <h3>Nombre:</h3>
                        <p className='title'>{name}</p>
                    </div>
                    <div className="preview-txt">
                        <h3>Sinopsis:</h3>
                        <p>{synopsis}</p>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="preview-txt column">
                    <h3>Generos:</h3>
                    <div className='row'>
                        {genres.map((name, index)=>(
                            <span className='genres' key={index}>{name}</span>
                        ))}
                    </div>
                </div>
                <div className="divider"></div>
                <div className="preview-txt row">
                    {tags.includes('airing') ? (<CheckRounded className='true' />) : (<CloseRounded className='false' />)}
                    <h2 className='align-center'>En emisión</h2>
                </div>
                <div className="preview-txt row">
                    {tags.includes('dub') ? (<CheckRounded className='true' />) : (<CloseRounded className='false' />)}
                    <h2 className='align-center'>Doblado</h2>
                </div>
                <div className="preview-txt row">
                    {tags.includes('sub') ? (<CheckRounded className='true' />) : (<CloseRounded className='false' />)}
                    <h2 className='align-center'>Subtitulado</h2>
                </div>
            </div>
        </div>
    )
}
