import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/auth/AuthContext';
import { AddCircleOutline, ArrowBack, Check, Delete } from '@mui/icons-material';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import Modal from '../../components/modals/Modal';
import Input from '../../components/form/Input';
import { ContentListsContext } from '../../context/ContentLists';
import SearchIcon from '../../assets/images/Search';

const Card = ({ data, list, section, setFetched }) => {
  const { lang } = useContext(AuthContext);
  const [ currentList, setCurrentList ] = useState(list.find(x => x.id === section.id));
  const [ loading, setLoading ] = useState(false);

  const deleteCard = () => {
    setLoading(true);

    import(`../../services/firebase/anime_${lang}`)
    .then((db) => {
      const homeList = doc(db.animeFirestore, `home/swiper_sections`);

      let newData = Object.assign({}, currentList);
      let oldData = Object.assign({}, currentList);
  
      let newArr = newData;
      newArr.animes = newData.animes.filter((x) => x.id !== data.id);

      updateDoc(homeList, {
        data: arrayUnion(newArr)
      }).then(() => {
        updateDoc(homeList, {
          data: arrayRemove(oldData)
        }).then(() => {
          setFetched();
        })
      })
    })
  }

  return (
    <div className='card'>
      <div className="thumbnail">
        <picture>
          <source src={data.cover} type='image/webp' />
          <img src={data.cover} alt="" />
        </picture>
        <Tooltip placement='top' title="Doble click para eliminar">
          <Button onDoubleClick={deleteCard} className={`button variant-danger ${loading && 'loading'}`}>
            <div className="button-icon"><Delete /></div>
            <div className="loading">
              <CircularProgress className='progress' />
            </div>
          </Button>
        </Tooltip>
      </div>
      <div className="data">
        {data.name}
      </div>
    </div>
  )
}

const SectionContainer = ({ data, list, setFetched }) => {
  const { lang } = useContext(AuthContext);

  const [ animes, setAnimes ] = useState(data.episodes ? [] : Array.from(data.animes).reverse());

  const [ loading, setLoading ] = useState(false);

  const [ serieId, setSerieId ] = useState('');

  const [ modal, setModal ] = useState(false);
  const [ modalState, setModalState ] = useState(null);
  const [ modalLoading, setModalLoading ] = useState(false);
  const [ disabled, setDisabled ] = useState(true);

  const openModal = () => {
    setModal(true);
    setTimeout(() => {
      setModalState('show');
    }, 100)
  }

  const closeModal = () => {
    setModalState(null);
    setTimeout(() => {
      setModal(false);
    }, 100)
  }

  const deleteSection = () => {
    import(`../../services/firebase/anime_${lang}`)
    .then(async (db) => {
      const homeList = doc(db.animeFirestore, `home/swiper_sections`);
      updateDoc(homeList, {
        data: arrayRemove(list.find(x => x.id === data.id))
      }).then(() => {
        setFetched(false);
      })
    })
  }

  const addSerie = (e) => {
    e.preventDefault();  
    if (!disabled) {
      setModalLoading(true);

      import(`../../services/firebase/anime_${lang}`)
      .then(async (db) => {
        const homeList = doc(db.animeFirestore, `home/swiper_sections`);
        const serieRef = doc(db.animeFirestore, `animes/${serieId}`);
        const serieSnap = await getDoc(serieRef);

        if (serieSnap.exists()) {
          const dataF = serieSnap.data().info[0];
          let newData = Object.assign({}, list.find(x => x.id === data.id));
          let newArr = newData;

          newArr.animes.push({cover: dataF.images.tall, id: serieSnap.id, name: dataF.name, tags: dataF.tags})

          updateDoc(homeList, {
            data: arrayUnion(newArr)
          }).then(() => {
            let oldData = Object.assign({}, list.find(x => x.id === data.id));
            oldData.animes = oldData.animes.filter((x) => x.id !== serieId);
            updateDoc(homeList, {
              data: arrayRemove(oldData)
            }).then(() => {
              closeModal();
              setFetched(false)
              setModalLoading(false);
              setSerieId('');
            })
          })
        } else {
          setModalLoading(false);
        }
      })
    }
  }

  useEffect(() => {
    if (serieId !== '') {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [serieId]);

  if (data.id.toString() === "1" || data.id.toString() === "2") {
    return;
  }

  return (
    <>
      <div className='flex flow-column gap-normal section-container'>
        <div className="header">
          <Tooltip title="Eliminar sección (Doble Click)">
            <IconButton className='IconButton' onDoubleClick={deleteSection}>
              <Delete />
            </IconButton>
          </Tooltip>
          <h3>{data.name}</h3>
        </div>
        <div className="body">
          <Button className='card add' onClick={openModal}>
            <div className="thumbnail">
              <AddCircleOutline />
            </div>
            <div className="data flex justify-center">
              AÑADIR
            </div>
          </Button>
          {!loading &&
            <>
              {animes.map((doc, i) =>(
                <Card 
                  key={i} 
                  list={list} 
                  setFetched={setFetched}
                  section={data} 
                  data={doc} 
                />
              ))}
            </>
          }
        </div>
      </div>
      {modal &&
        <Modal
          title="Añadir serie"
          visible={modalState}
          close={closeModal}
        >
          <form className={`form ${modalLoading && 'loading'}`} onSubmit={addSerie}>
            <div style={{ padding: '1rem 1rem 0'}}>
              <Input 
                label
                labelText="*ID de la serie"
                variant={1}
                value={serieId}
                onChange={(e) => setSerieId(e.target.value)}
                required
              />
            </div>
            <div className="buttons-group">
              <Button 
                type='submit' 
                className={`button size-big variant-1 ${modalLoading && 'loading'}`}
                disabled={disabled}
              >
                <div className="button-title">Añadir</div>
                <div className="loading">
                  <CircularProgress className='progress' />
                </div>
              </Button>
            </div>
          </form>
        </Modal>
      }
    </>
  )
}

const UploadSection = ({ list, setFetched, setUploadSections }) => {
  const { lang } = useContext(AuthContext);
  const { animes, setAnimes } = useContext(ContentListsContext);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');

  const [ selectedSeries, setSelectedSeries ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ formDisabled, setFormDisabled ] = useState(true);
  
  const [ modal, setModal ] = useState(false);
  const [ modalState, setModalState ] = useState(null);
  const [ modalLoading, setModalLoading ] = useState(false);
  const [ disabled, setDisabled ] = useState(true);

  const [ searchTerm, setSearchTerm ] = useState('');
  const [ searching, setSearching ] = useState(false);
  const [ filteredSearchAnimes, setFilteredSearchAnimes ] = useState(null);
  const [ selectedSerie, setSelectedSerie ] = useState(null);

  const openModal = () => {
    setModal(true);
    setTimeout(() => {
      setModalState('show');
    }, 100)
  }

  const closeModal = () => {
    setModalState(null);
    setTimeout(() => {
      setModal(false);
    }, 100)
  }

  const addSerie = (e) => {
    e.preventDefault();
    setModalLoading(true);

    if (selectedSeries.find(x => x.id === selectedSerie.id)) {
      return;
    } else {
      setSelectedSeries(selectedSeries.concat(selectedSerie));
      setTimeout(() => {
        setSelectedSerie(null);
        setModalLoading(false);
        closeModal();
      }, 200)
    }
  }

  const Card = ({ data }) => {
    const deleteCard = () => {
      let arr = selectedSeries.slice(0);
      let arrNew = arr.filter((x) => x.id !== data.id);

      setSelectedSeries(arrNew);
    }

    return (
      <div className='card'>
        <div className="thumbnail">
          <picture>
            <source src={data.cover} type='image/webp' />
            <img src={data.cover} alt="" />
          </picture>
          <Tooltip placement='top' title="Doble click para eliminar">
            <Button onDoubleClick={deleteCard} className={`button variant-danger`}>
              <div className="button-icon"><Delete /></div>
              <div className="loading">
                <CircularProgress className='progress' />
              </div>
            </Button>
          </Tooltip>
        </div>
        <div className="data">
          {data.name}
        </div>
      </div>
    )
  }

  const uploadSection = (e) => {
    e.preventDefault();
    if (!formDisabled) {
      setLoading(true);
      import(`../../services/firebase/anime_${lang}`)
      .then((db) => {
        const homeList = doc(db.animeFirestore, `home/swiper_sections`);

        let section = {
          animes: selectedSeries,
          id: id,
          name: name
        }
        updateDoc(homeList, {
          data: arrayUnion(section)
        }).then(() => {
          setFetched(false);
          setUploadSections(false);
        })
      })
    }
  } 

  useEffect(() => {
    if (selectedSerie) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [selectedSerie]);

  useEffect(() => {
    if (name !== '') {
      if (selectedSeries.length > 0) {
        setFormDisabled(false);
      } else {
        setFormDisabled(true);
      }
    } else {
      setFormDisabled(true);
    }
  }, [name, selectedSeries])

  useEffect(() => {
    const last = list[list.length - 1];
    setId(last.id * 1 + 1)
  }, []);

  useEffect(() => {
    setSearching(true);

    function getSearchData() {
      if (animes.length > 0) {
        setFilteredSearchAnimes(
          animes.filter(
            (x) => 
            x.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
        setSearching(false);
      } else {
        import(`../../services/firebase/anime_${lang}`)
        .then(async (db) => {
            const searchRef = doc(db.animeFirestore, `search/animes`);
            const searchSnap = await getDoc(searchRef);

            const data = searchSnap.data().list;
            setAnimes(data);
            
            setFilteredSearchAnimes(
                data.filter(
                    (x) =>
                    x.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setSearching(false);
        })
      }
    }

    if (searchTerm === "" || !searchTerm) {
      setSearching(false);
      setFilteredSearchAnimes(null);
      return;
    }

    const delayDebouncefn = setTimeout(() => {
      getSearchData();
    }, 400);

    return () => clearTimeout(delayDebouncefn);
  }, [searchTerm]);

  return (
    <>
      <form className={`form section-container ${loading && 'loading'}`} onSubmit={uploadSection}>
        <div className='flex flow-row gap-normal align-end'>
          <Input 
            label
            labelText={"Nombre de la sección"}
            variant={1}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div>
            <Button className={`button variant-1 size-big ${loading && 'loading'}`} type="submit" disabled={formDisabled}>
              <div className="button-title">¡Enviar!</div>
              <div className="loading">
                <CircularProgress className='progress' />
              </div>
            </Button>
          </div>
        </div>
        <div className="body">
          <Button className='card add' onClick={openModal}>
            <div className="thumbnail">
              <AddCircleOutline />
            </div>
            <div className="data flex justify-center">
              AÑADIR
            </div>
          </Button>
          
          {selectedSeries.slice(0).reverse().map((doc, i) =>(
            <Card 
              key={i} 
              data={doc} 
            />
          ))}
        </div>
      </form>
      {modal &&
        <Modal
          title="Añadir serie"
          visible={modalState}
          close={closeModal}
        >
          <form className={`form ${modalLoading && 'loading'}`} onSubmit={addSerie}>
            <div style={{ padding: '1rem 1rem 0'}}>
               <Input 
                placeholder
                placeholderText={"Busca tu anime"}
                type={"search"}
                value={searchTerm}
                variant={"search"}
                icon={searching ? <CircularProgress className='input-icon' /> : <SearchIcon className="input-icon" />}
                autoFocus
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div className='flex flow-column gap-normal' style={{ padding: '0 1rem'}}>
              {filteredSearchAnimes ?
                <>
                  {filteredSearchAnimes.length > 0 ?
                    <>
                      {filteredSearchAnimes.map((data, i) => (
                        <Button 
                          className={`button variant-3 ${id === data.id && 'active'}`} 
                          key={i}
                          style={{
                              overflow: 'hidden',
                              borderRadius: 2
                          }}
                          onClick={() => {
                            const obj = {
                              cover: data.images.tall,
                              id: data.id,
                              name: data.name,
                              tags: data.tags
                            }
                            setSelectedSerie(obj);
                            setSearchTerm("");
                            setFilteredSearchAnimes(null);
                          }}
                        >
                          <picture 
                            style={{ 
                              position: 'absolute',
                              zIndex: 0,
                              left: 0,
                              width: '100%',
                              opacity: .2
                            }}>
                            <source src={data.images.wide} />
                            <img style={{ width: '100%'}} src={data.images.wide} alt="" />
                          </picture>
                          {id === data.id && <div className="button-icon" style={{ position: 'relative'}}><Check /></div>}
                          <div className="button-title" style={{ position: 'relative'}}>{data.name}</div>
                        </Button>
                      ))}
                    </>
                    :
                    <h2 style={{ margin: '1rem auto 0', textAlign: 'center'}}>Sin resultados.</h2>
                  }
                </>
                :
                <>{selectedSerie && <h2>{selectedSerie.name}</h2>}</>
              }
            </div>
            <div className="buttons-group">
              <Button 
                type='submit' 
                className={`button size-big variant-1 ${modalLoading && 'loading'}`}
                disabled={disabled}
              >
                <div className="button-title">Añadir</div>
                <div className="loading">
                  <CircularProgress className='progress' />
                </div>
              </Button>
            </div>
          </form>
        </Modal>
      }
    </>
  )
}

export default function Sections() {
  const { lang } = useContext(AuthContext);
  const [ data, setData ] = useState([]);

  const [ uploadSections, setUploadSections ] = useState(false);
  const [ fetched, setFetched ] = useState(false);

  function getData() {
    if (!fetched) {
      import(`../../services/firebase/anime_${lang}`)
      .then(async (db) => {
        const dataRef = doc(db.animeFirestore, `home/swiper_sections`);
        const dataSnap = await getDoc(dataRef);

        const data = dataSnap.data().data;
        data.sort(function(a, b) {
          return a.id - b.id
        })

        setData(data);
        setFetched(true);
      })
    }
  }

  useEffect(() => {
    if (!fetched) {
      getData();
    }
  }, [fetched])

  return (
    <div className='sections-layout'>
      <div className="flex justify-center">
        {uploadSections ?
          <Button className='button variant-1 size-big gap-normal flex' onClick={() => setUploadSections(false)}>
            <div className="button-icon"><ArrowBack /></div>
            <div className="button-title">Volver</div>
          </Button>
          :
          <Button className='button variant-1 size-big gap-normal flex' onClick={() => setUploadSections(true)}>
            <div className="button-icon"><AddCircleOutline /></div>
            <div className="button-title">Añadir sección</div>
          </Button>
        }
      </div>
      <div className="sections-layout__body">
        {uploadSections ?
          <UploadSection setUploadSections={setUploadSections} setFetched={setFetched} list={data} />  
          :
          <>
            {fetched &&
              <>
                {data.map((doc, i) => (
                  <SectionContainer list={data} setFetched={setFetched} key={i} data={doc} />
                ))}
              </>
            }
          </>
        }
      </div>
    </div>
  )
}
