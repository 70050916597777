import React, { useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { ContentListsContext } from '../../context/ContentLists';
import { AuthContext } from '../../context/auth/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';

const Result = ({data}) => {
    return (
        <Link to={`/anime/${data.id}`} className='result-card'>
            <div className="result-card__thumbnail">
                <img
                    src={data.images.wide}
                    alt={data.name}
                />
            </div>
            <div className="result-card__body">
                <h4 className="result-card__body-title">{data.name}</h4>
                <div className="tags">
                    {data.tags.map((tag, i) => (
                        <span key={i}>{tag}</span>
                    ))}
                </div>
            </div>
        </Link>
    )
}

export default function Results({ searching, setSearching }) {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const { animes, setAnimes } = useContext(ContentListsContext);

    const [ filtered, setFiltered ] = useState([]);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        if (params.search) {
            setFiltered(
                animes.filter(
                    (data) => 
                    data.name.toLowerCase().includes(params.search.toLocaleLowerCase())
                )
            )
            setSearching(false);
        }
    }, [params.search, fetched]);

    useEffect(() => {
        let ignore = false;

        function fetchData() {
            import(`../../services/firebase/anime_${lang}`)
            .then(async (db) => {
                const animesRef = doc(db.animeFirestore, `search/animes`);
                const animesSnap = await getDoc(animesRef);

                const data = animesSnap.data().list;
                setAnimes(data);
                setFetched(true);
            })
        }

        if (!ignore) {
            if (!fetched) {
                if (animes.length < 1) {
                    fetchData();
                } else {
                    setFetched(true);
                }
            }
        }

        return () => ignore = true;
    }, [searching]);

    if (!fetched || searching) {
        return (
            <div className='progress' style={{marginTop: '1rem'}}>
                <CircularProgress className='progress-circular' sx={{width: '10vmin !important', height: '10vmin !important'}} />
            </div>
        )
    }

    return (
        <div className='search__results-layout'>
            <div className={`search__results-body`}>
                {filtered.map((data, i) => (
                    <Result key={i} data={data} />
                ))}
            </div>
        </div>
    )
}
