import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function SEO({ title }) {
  return (
    <Helmet prioritizeSeoTags>
        <title>{title} - aodesu</title>
        <meta name="og:type" content="website" />
        <meta name="og:title" content={`${title} - aodesu`} />
        <meta name="twitter:title" content={`${title} - aodesu`} />
    </Helmet>
  )
}
