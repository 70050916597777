import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { AddBox, AddBoxOutlined, Cottage, CottageOutlined, Logout } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import './Sidebar.scss';
import Logo from '../../../assets/images/Logo';
import SearchFilledIcon from '../../../assets/images/SearchFilled';
import SearchIcon from '../../../assets/images/Search';
import { AuthContext } from '../../../context/auth/AuthContext';

const SidebarButton = ({
    title,
    to,
    filledIcon,
    outlinedIcon
}) => {
    return (
        <Button
            className='button variant-3'
            component={NavLink}
            to={to}
        >
            <div className="button-icon">
                {filledIcon}
                {outlinedIcon}    
            </div>
            <div className="button-title">{title}</div>
        </Button>
    )
}

export default function Sidebar() {
    const { signOut, lang } = useContext(AuthContext);
    return (
        <div className='sidebar'>
            <div className="sidebar-header">
                <Logo />
            </div>
            <div className="sidebar-body">
                <span className='lang'>{lang}</span>
                <SidebarButton 
                    to="/home" 
                    filledIcon={<Cottage className='filled' />} 
                    outlinedIcon={<CottageOutlined className='outlined' />}
                    title="Inicio" 
                />
                <SidebarButton 
                    to="/search" 
                    filledIcon={<SearchFilledIcon className='filled' />} 
                    outlinedIcon={<SearchIcon className='outlined' />}
                    title="Buscar" 
                />
                <SidebarButton 
                    to="/upload" 
                    filledIcon={<AddBox className='filled' />}
                    outlinedIcon={<AddBoxOutlined className='outlined' />}
                    title="Subir" 
                />
                <Button
                    className='button variant-3'
                    onClick={signOut}
                >
                    <div className="button-icon">
                        <Logout />  
                    </div>
                    <div className="button-title">Cerrar sesión</div>
                </Button>
            </div>
        </div>
  )
}
