import React, { useContext, useEffect, useState } from 'react';
import './index.scss';
import { Button } from '@mui/material';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import { Cottage, FormatListNumbered, Info } from '@mui/icons-material';
import { ContentListsContext } from '../../context/ContentLists';
import { AuthContext } from '../../context/auth/AuthContext';
import { doc, getDoc } from 'firebase/firestore';

export default function Anime() {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const { animes, currentAnime, setCurrentAnime } = useContext(ContentListsContext);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let ignore = false;

        async function getData() {
            import(`../../services/firebase/anime_${lang}`)
            .then(async (db) => {
                const animeRef = doc(db.animeFirestore,  `animes/${params.id}`);
                const animeSnap = await getDoc(animeRef);

                const arr = animeSnap.data().info[0]

                arr.id = animeSnap.id;
                setCurrentAnime(arr);
                setLoading(false)
            })
        }

        if (!ignore) {
            if (JSON.stringify(currentAnime) === '[]') {
                getData();
            } else {
                if (currentAnime.id === params.id){
                    setLoading(false);
                } else {
                    getData();
                }
            }
        }

        return () => ignore = true;
    }, [])
    
    if (loading) {
        return (
            <div className='anime-layout'>
                <div className="anime-layout__header">
                    <div className="data">
                        <div className="data-layout">
                            <div className="thumbnail"></div>
                            <div className="data">
                                <div className="h1"></div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-tabs">
                        <div className="button"></div>
                        <div className="button"></div>
                        <div className="button"></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='anime-layout'>
            <div className="anime-layout__header">
                <div className="data">
                    <div className="data-layout">
                        <div className="thumbnail">
                            <picture>
                                <source srcSet={currentAnime.images.tall} type='image/webp' />
                                <img src={currentAnime.images.tall} alt="" />
                            </picture>
                        </div>
                        <div className="data">
                            <h1>{currentAnime.name}</h1>
                        </div>
                    </div>
                </div>
                <nav className="nav-tabs">
                    <Button
                        component={NavLink}
                        className='button'
                        to={`/anime/${params.id}`}
                        end
                    >
                        <div className="button-icon"><Cottage /></div>
                        <div className="button-title">Inicio</div>
                    </Button>
                    <Button
                        component={NavLink}
                        className='button'
                        to={`/anime/${params.id}/info`}
                    >
                        <div className="button-icon"><Info /></div>
                        <div className="button-title">Información</div>
                    </Button>
                    <Button
                        component={NavLink}
                        className='button'
                        to={`/anime/${params.id}/seasons`}
                        end
                    >
                        <div className="button-icon"><FormatListNumbered /></div>
                        <div className="button-title">Temporadas</div>
                    </Button>
                </nav>
            </div>
            <div className="anime-layout__body">
                <Outlet />
            </div>
        </div>
    )
}
