import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import App from './App';
import AoRouter from './services/seo/router';
import './assets/styles/index.scss';

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <App>
        <RouterProvider router={AoRouter} />
      </App>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
