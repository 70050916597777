import { createContext, useState } from "react";

export const ContentListsContext = createContext();

export const ContentListsProvider = ({ children }) => {
    const [ animes, setAnimes ] = useState([]);
    const [ currentAnime, setCurrentAnime ] = useState([])

    return (
        <ContentListsContext.Provider
            value={{
                animes, 
                setAnimes,
                currentAnime,
                setCurrentAnime
            }}
        >
            {children}
        </ContentListsContext.Provider>
    )
}