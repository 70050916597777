import { createContext, useState } from "react";

export const ModalsContext = createContext();

export const ModalsProvider = ({ children }) => {
    const [ selectImage, setSelectImage ] = useState(false);
    const [ selectImageState, setSelectImageState ] = useState(null);

    const [ selectImageObject, setSelectImageObject ] = useState(null);
    const [ selectImageTall, setSelectImageTall ] = useState(null);
    const [ selectImageTallPreview, setSelectImageTallPreview ] = useState(null);
    
    const [ selectImageWide, setSelectImageWide ] = useState(null);
    const [ selectImageWidePreview, setSelectImageWidePreview ] = useState(null);
    const [ selectImageWidth, setSelectImageWidth ] = useState(0);
    const [ selectImageHeight, setSelectImageHeight ] = useState(0);

    function openSelectImage(image, width, height) {
        setSelectImage(true);
        setSelectImageWidth(width);
        setSelectImageHeight(height);
        setSelectImageObject(image);
        setTimeout(() => {
            setSelectImageState('show');
        }, 100);
    }

    function closeSelectImage() {
        setSelectImageState(null);
        setTimeout(() => {
            setSelectImageWidth(0);
            setSelectImageHeight(0);
            setSelectImageObject(null);
            setSelectImage(false);
        }, 100);
    }

    return (
        <ModalsContext.Provider
            value={{
                selectImage,
                selectImageState,
                selectImageObject,
                setSelectImageObject,
                selectImageTall,
                setSelectImageTall,
                selectImageWide,
                setSelectImageWide,
                selectImageWidth,
                selectImageHeight,
                openSelectImage,
                closeSelectImage,
                selectImageWidePreview,
                setSelectImageWidePreview,
                selectImageTallPreview,
                setSelectImageTallPreview
            }}
        >
            {children}
        </ModalsContext.Provider>
    )
}