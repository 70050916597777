import { Image, Storage } from '@mui/icons-material';
import { Button } from '@mui/material'
import React from 'react'
import { NavLink, Outlet, useParams } from 'react-router-dom'

export default function AnimeInfo() {
    const params = useParams();
  return (
    <div>
        <div className="anime-layout__header">
            <nav className="nav-tabs">
                <Button
                    component={NavLink}
                    className='button'
                    style={{maxWidth: 'inherit'}}
                    to={`/anime/${params.id}/info/visual`}
                >
                    <div className="button-icon"><Image /></div>
                    <div className="button-title">Visual</div>
                </Button>
                <Button
                    component={NavLink}
                    className='button'
                    style={{maxWidth: 'inherit'}}
                    to={`/anime/${params.id}/info/basic`}
                >
                    <div className="button-icon"><Storage /></div>
                    <div className="button-title">Básica</div>
                </Button>
            </nav>
        </div>
        <Outlet />
    </div>
  )
}
