import { onAuthStateChanged } from 'firebase/auth'
import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/auth/AuthContext'
import Sidebar from '../components/form/navigation/Sidebar';

export default function Layout() {
    const { lang, setLang } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        import(`../services/firebase/anime_${lang}`)
        .then((db) => {
            onAuthStateChanged(db.animeAuth, (user) => {
                if (!user) {
                    navigate('/')
                }
            })
        })
    })

    return (
        <>
            <div className="layout">
                <Sidebar />
                <div className='content'>
                    <Outlet />
                </div>
            </div>
        </>
    )
}
