import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { ContentListsContext } from "../ContentLists";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const { setAnimes } = useContext(ContentListsContext);
    const [ lang, setLang ] = useState('es');
    const [ user, setUser ] = useState('');
    const [ ip, setIp ] = useState('');

    function signOut() {
        import(`../../services/firebase/anime_${lang}`)
        .then((db) => {
            db.animeAuth.signOut();
            setAnimes([]);
        })
    }

    const getIp = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIp(res.data.ip);
      };

    useEffect(() => {
        if(ip===''){
            getIp();
        }
    })

    useEffect(() => {
        (async () => {
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                db.animeAuth.onAuthStateChanged(setUser);
            })
        })();
    }, []);

    return(
        <AuthContext.Provider
            value={{
                user,
                lang,
                setLang,
                signOut,
                ip
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}