import React, { useContext, useEffect, useRef, useState } from 'react'
import './Upload.scss';
import SEO from '../../services/seo/seo';
import Input from '../../components/form/Input';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Box, Button, Chip, CircularProgress, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material';
import animeGenres from '../../utils/Genres';
import Preview from './Preview';
import { ModalsContext } from '../../context/Modals';
import Modal from '../../components/modals/Modal';
import SelectImage from '../../components/modals/SelectImage';
import { Check, PendingActions } from '@mui/icons-material';
import { AuthContext } from '../../context/auth/AuthContext';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import SearchIcon from '../../assets/images/Search';
/* import { Box, Chip, CircularProgress, MenuItem, OutlinedInput, Select } from '@mui/material';
import { UploadRounded } from '@mui/icons-material';
import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore, storage } from '../../services/firebase/firebase';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression'; */

export default function Upload() {
    const navigate = useNavigate();
    const { lang } = useContext(AuthContext);
    const { selectImage, selectImageTall, selectImageWide, openSelectImage, selectImageState, closeSelectImage } = useContext(ModalsContext);
    const inputRef = useRef(null);
    const [ id, setId ] = useState(uuid().slice(24,36));

    const [ name, setName ] = useState('');
    const [ synopsis, setSynopsis ] = useState('');
    const [ genres, setGenres ] = useState([]);
    const [ tags, setTags ] = useState([]);

    const [ MAL, setMAL ] = useState("");
    const [ rating, setRating ] = useState("G");
    
    const [ imageType, setImageType ] = useState('');

    const [ loading, setLoading ] = useState(false);

    const [ searchTerm, setSearchTerm ] = useState("");
    const [ searchLang, setSearchLang ] = useState(lang);
    const [ searchAnimes, setSearchAnimes ] = useState([]);
    const [ filteredSearchAnimes, setFilteredSearchAnimes ] = useState(null);
    const [ linkLang, setLinkLang ] = useState(false);
    const [ searching, setSearching ] = useState(false);
    const [ selected, setSelected ] = useState('');
    const [ linkDisabled, setLinkDisabled ] = useState(true);

    const handleGenres = (event) => {
        const {
            target: { value },
        } = event;
        setGenres(
            typeof value === 'string' ? value.toString(',') : value,
        );
    };

    const handleImageChange = (e) => {
        if (imageType === "tall") {
            openSelectImage(e.target.files[0], 480, 720);
            inputRef.current.value = "";
        } else if (imageType === "wide") {
            openSelectImage(e.target.files[0], 1200, 675);
            inputRef.current.value = "";
        }
    }

    function handleTags(value){
        if(tags.includes(value)){
            setTags(tags.filter(cat => cat !== value))
        } else {
            setTags(tags.concat(value))
        }
    }

    const handleRating = (e) => {
        setRating(e.target.value);
    }

    const handleLang = (e) => {
        setSearchLang(e.target.value);
        setSearchAnimes([]);
    }

    const uploadAnime = (e) => {
        e.preventDefault();
        setLoading(true);

        import(`../../services/firebase/anime_${lang}`)
        .then((db) => {
            const serieRef = doc(db.animeFirestore, `animes/${id}`);
            const seasonsInfo = doc(db.animeFirestore, `animes/${id}/seasons/info`);
            const season = doc(db.animeFirestore, `animes/${id}/seasons/1`);
            const search = doc(db.animeFirestore, `search/animes`);
            const homeList = doc(db.animeFirestore, `home/swiper_sections`);

            const tallCover = ref(db.animeStorage, `animes/${id}/tall.webp`);
            const wideCover = ref(db.animeStorage, `animes/${id}/wide.webp`);

            fetch(`https://api.jikan.moe/v4/anime/${MAL}`)
            .then((response) => response.json())
            .then((dataMAL) => {
                uploadBytes(tallCover, selectImageTall)
                .then((response) => {
                    getDownloadURL(response.ref)
                    .then((url) => {
                        const tallLink = url;
                        uploadBytes(wideCover, selectImageWide)
                        .then((response) => {
                            getDownloadURL(response.ref)
                            .then((url) => {
                                const wideLink = url;
                                setDoc(serieRef, {
                                    info: [
                                        {
                                            name: name,
                                            synopsis: synopsis,
                                            categories: genres,
                                            tags: tags,
                                            images: {
                                                tall: tallLink,
                                                wide: wideLink
                                            }
                                        }
                                    ]
                                }).then(() => {
                                    setDoc(seasonsInfo, {
                                        data: [
                                            {
                                                MAL: MAL,
                                                id: "1",
                                                name: name,
                                                rating: rating
                                            }
                                        ]
                                    }).then(() => {
                                        setDoc(season, {
                                            episodes_list: []
                                        }).then(() => {
                                            updateDoc(search, {
                                                list: arrayUnion({
                                                    name: name,
                                                    name_alternative: dataMAL.data.title,
                                                    categories: genres,
                                                    tags: tags,
                                                    images: {
                                                        tall: tallLink,
                                                        wide: wideLink,
                                                    },
                                                    id: id,
                                                })
                                            }).then(async () => {
                                                genres.map(async (genre) => {
                                                    const genreRef = doc(db.animeFirestore, `genres/${genre}`);
                                                    const genreSnap = await getDoc(genreRef);
    
                                                    if (genreSnap.exists()) {
                                                        updateDoc(genreRef, {
                                                            data: arrayUnion({
                                                                name: name,
                                                                cover: tallLink,
                                                                id: id,
                                                                tags: tags
                                                            })
                                                        })
                                                    } else {
                                                        setDoc(genreRef, {
                                                            data: arrayUnion({
                                                                name: name,
                                                                cover: tallLink,
                                                                id: id,
                                                                tags: tags
                                                            })
                                                        })
                                                    }
                                                })
    
                                                const listSnap = await getDoc(homeList);
                    
                                                if (listSnap.exists()) {
                                                    const data = listSnap.data().data.find(x => x.id === "1");
                                                    const oldData = listSnap.data().data.find(x => x.id === "1");
                                                    const newArr = data;
                                                    newArr.animes.push({cover: tallLink, id: id, name: name, tags: tags});
    
                                                    if (newArr.animes.length >= 15) {
                                                        newArr.animes.splice(0, 1);
                                                    }
                                                    
                                                    updateDoc(homeList, {
                                                        data: arrayUnion(newArr)
                                                    }).then(() => {
                                                        updateDoc(homeList, {
                                                            data: arrayRemove(oldData)
                                                        }).then(() => {
                                                            navigate(`/anime/${id}`)
                                                        })
                                                    })
                                                }
                                            })
                                        })
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    }

    useEffect(() => {
        setSearching(true);

        function getSearchData() {
            if (searchAnimes.length > 0) {
                setFilteredSearchAnimes(
                    searchAnimes.filter(
                        (x) =>
                        x.name.toLowerCase().includes(searchTerm.toLowerCase())
                    ))
                setSearching(false);
                
            } else {
                import(`../../services/firebase/anime_${searchLang}`)
                .then(async (db) => {
                    const searchRef = doc(db.animeFirestore, `search/animes`);
                    const searchSnap = await getDoc(searchRef);

                    const data = searchSnap.data().list;
                    setSearchAnimes(data);
                    
                    setFilteredSearchAnimes(
                        data.filter(
                            (x) =>
                            x.name.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                    );
                    setSearching(false);
                })
            }
        }

        if (searchTerm === "" || !searchTerm) {
            setSearching(false);
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            getSearchData();
        }, 400);

        return () => clearTimeout(delayDebounceFn);
    }, [searchTerm, searchLang]);

    useEffect(() => {
        if (selected !== '') {
            setLinkDisabled(false);
        } else {
            setLinkDisabled(true);
        }
    }, [selected])

    return (
        <>
            <SEO title="Subir" />
            <div className='upload'>
                <div className="form-layout">
                    <form className={`form ${loading && 'loading'}`} onSubmit={uploadAnime}>
                        <div className="section">
                            <h2>Información Básica</h2>
                            <div className='flex flow-column gap-normal'>
                                <div className='flex flow-row gap-normal align-end'>
                                    <Input 
                                        label
                                        labelText="ID"
                                        type="text"
                                        value={id}
                                        readOnly
                                        required
                                        variant={1}
                                    />
                                    <div className="div">
                                        <Button onClick={() => setLinkLang(true)} className='button variant-1' style={{ padding: '.75rem'}}>
                                            <div className="button-title">Vincular con otro idioma</div>
                                        </Button>
                                    </div>
                                </div>
                                {linkLang &&
                                    <div className='flex flow-column gap-normal' style={{marginBottom: '1rem'}}>
                                        <div style={{ margin: '2rem 0'}} className='flex flow-column gap-normal'>
                                            <div className="flex flow-row gap-big">
                                                <Input 
                                                    type="text"
                                                    value={searchTerm}
                                                    placeholder
                                                    placeholderText="Busca lo que necesitas..."
                                                    variant="search"
                                                    onChange={(e) => setSearchTerm(e.target.value)}
                                                    icon={searching ? <CircularProgress className='input-icon' /> : <SearchIcon className="input-icon" />}
                                                    autoFocus
                                                />
                                                <FormControl size="small">
                                                    <Select
                                                        className='select'
                                                        value={searchLang}
                                                        onChange={handleLang}
                                                    >
                                                        <MenuItem className='button variant-2' value="es">ES</MenuItem>
                                                        <MenuItem className='button variant-2' value="en">EN</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='flex flow-column gap-normal'>
                                                {filteredSearchAnimes ?
                                                    <>
                                                        {filteredSearchAnimes.length > 0 ?
                                                            <>
                                                                {filteredSearchAnimes.map((data, i) => (
                                                                    <Button 
                                                                        className={`button variant-3 ${selected === data.id && 'active'}`} 
                                                                        key={i}
                                                                        style={{
                                                                            overflow: 'hidden',
                                                                            borderRadius: 2
                                                                        }}
                                                                        onClick={() => setSelected(data.id)}
                                                                    >
                                                                        <picture 
                                                                            style={{ 
                                                                                position: 'absolute',
                                                                                zIndex: 0,
                                                                                left: 0,
                                                                                width: '100%',
                                                                                opacity: .2
                                                                            }}>
                                                                            <source src={data.images.wide} />
                                                                            <img style={{ width: '100%'}} src={data.images.wide} alt="" />
                                                                        </picture>
                                                                        {selected === data.id && <div className="button-icon" style={{ position: 'relative'}}><Check /></div>}
                                                                        <div className="button-title" style={{ position: 'relative'}}>{data.name}</div>
                                                                    </Button>
                                                                ))}
                                                            </>
                                                            :
                                                            <h2 style={{ margin: '1rem auto 0', textAlign: 'center'}}>Sin resultados.</h2>
                                                        }
                                                    </>
                                                    :
                                                    <h2 style={{ margin: '1rem auto 0', textAlign: 'center'}}>Busca por nombre</h2>
                                                }
                                            </div>
                                        </div>
                                        <div className="buttons-group flex gap-normal">
                                            <Button onClick={() => setLinkLang(false)} className='button outline size-big'>
                                                <div className="button-title">Cancelar</div>
                                            </Button>
                                            <Button 
                                                className='button variant-1 size-big' 
                                                disabled={linkDisabled}
                                                onClick={() => {
                                                    if (!linkDisabled) {
                                                        setId(selected);
                                                        setSearchTerm('');
                                                        setFilteredSearchAnimes('');
                                                        setLinkLang(false);
                                                    }
                                                }}
                                            >
                                                <div className="button-title">Vincular</div>
                                            </Button>
                                        </div>
                                    </div>
                                }
                                <Input 
                                    label
                                    labelText="*Nombre"
                                    type="text"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    variant={1}
                                />
                                <Input 
                                    label
                                    labelText="*Sinopsis"
                                    type="text-area"
                                    value={synopsis}
                                    onChange={(e) => setSynopsis(e.target.value)}
                                    required
                                    variant={1}
                                />
                                <FormControl size="small" fullWidth>
                                    <label htmlFor="genres">*Géneros</label>
                                    <Select
                                        multiple
                                        required
                                        id="genres"
                                        name="genres"
                                        className='select'
                                        value={genres}
                                        onChange={handleGenres}
                                        input={<OutlinedInput id="select-multiple-chip" label="Chip" className='chip' />}
                                        renderValue={(selected) => (
                                            <Box>
                                                {selected.map((value) => (
                                                    <Chip className='chips' key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                    >
                                        {animeGenres.map((e) => (
                                            <MenuItem
                                                className='button variant-2'
                                                key={e.id}
                                                value={e.id}
                                                style={{gap: '.5rem'}}
                                            >
                                                {e.icon}
                                                {e.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="section">
                            <h2>Información visual</h2>
                            <div className="flex flow-column gap-normal">
                                <Button 
                                    className={`button variant-4 warning ${selectImageTall && 'active'}`} 
                                    onClick={() => {
                                        setImageType('tall');
                                        inputRef.current.click();
                                    }}
                                >
                                    <div className="button-title">Imagen alta</div>
                                    <div className="button-icon">
                                        {selectImageTall ?
                                            <Check />
                                            :
                                            <PendingActions />
                                        }
                                    </div>
                                </Button>
                                <Button 
                                    className={`button variant-4 warning ${selectImageWide && 'active'}`} 
                                    onClick={() => {
                                        setImageType('wide');
                                        inputRef.current.click();
                                    }}
                                >
                                    <div className="button-title">Imagen ancha</div>
                                    
                                    <div className="button-icon">
                                        {selectImageWide ?
                                            <Check />
                                            :
                                            <PendingActions />
                                        }
                                    </div>
                                </Button>
                            </div>
                        </div>
                        <div className="section">
                            <h2>Información adicional</h2>
                            <div className="flex flow-column gap-big" style={{ marginBottom: '1.5rem'}}>
                                <Input 
                                    label
                                    labelText="*My Anime List ID"
                                    type="text"
                                    value={MAL}
                                    onChange={(e) => setMAL(e.target.value)}
                                    required
                                    variant={1}
                                />
                                <FormControl size='small'>
                                    <label htmlFor="rating">*Rating</label>
                                    <Select className='select'
                                        value={rating}
                                        onChange={handleRating}
                                    >
                                        <MenuItem className='button variant-2' value="G">G - Todas las edades</MenuItem>
                                        <MenuItem className='button variant-2' value="PG">PG - Niños</MenuItem>
                                        <MenuItem className='button variant-2' value="PG-13">PG-13 - De 13 o mayores</MenuItem>
                                        <MenuItem className='button variant-2' value="R-17+">R-17+ - 17 años con supervisión (Violencia/Obscenidades)</MenuItem>
                                        <MenuItem className='button variant-2' value="R+">R+ - Desnudez leve</MenuItem>
                                        <MenuItem className='button variant-2' value="Rx">Rx - Hentai</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="flex flow-column gap-normal">
                                <Button onClick={() => handleTags('airing')} className={`button variant-toggle ${tags.includes('airing') && 'active'}`}>
                                    <div className="button-title">En emisión</div>
                                    <div className="toggle"></div>
                                </Button>
                                <Button onClick={() => handleTags('sub')} className={`button variant-toggle ${tags.includes('sub') && 'active'}`}>
                                    <div className="button-title">Subtitulado</div>
                                    <div className="toggle"></div>
                                </Button>
                                <Button onClick={() => handleTags('dub')} className={`button variant-toggle ${tags.includes('dub') && 'active'}`}>
                                    <div className="button-title">Doblado</div>
                                    <div className="toggle"></div>
                                </Button>
                            </div>
                        </div>
                        <div className="buttons-group">
                            <Button type='submit' className={`button variant-1 size-big ${loading && 'loading'}`} >
                                <div className="button-title">¡Subir!</div>
                                <div className="loading">
                                    <CircularProgress className='progress' />
                                </div>
                            </Button>
                        </div>
                    </form>
                </div>
                
                <input 
                    type="file" 
                    accept='image/*,.jpe'
                    id="input-image"
                    className='hide'
                    ref={inputRef}
                    onChange={handleImageChange}
                />
                <Preview 
                    name={name}
                    synopsis={synopsis}
                    genres={genres}
                    tags={tags}
                />
            </div>
            
            {selectImage &&
                <Modal
                    title="Seleccionar imagen"
                    close={closeSelectImage}
                    variant={"select-image"}
                    visible={selectImageState}
                >
                    <SelectImage />
                </Modal>
            }
        </>
    )
}
