import React, { useContext, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor';
import { ModalsContext } from '../../context/Modals';
import { Button } from '@mui/material';
import Compressor from 'compressorjs';

export default function SelectImage() {
  const cropRef = useRef(null);
  const { 
    selectImageObject, 
    selectImageWidth, 
    selectImageHeight, 
    setSelectImageTall, 
    setSelectImageWide, 
    setSelectImageTallPreview,
    setSelectImageWidePreview,
    closeSelectImage
  } = useContext(ModalsContext);

  let tallBorer = [0, 0];
  let wideBorder = [0, 0];

  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImageScaledToCanvas().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      const file = new File([blob], "image", { type: 'image/webp'})
      
      const quality = selectImageWidth === 320 ? .8 : .7;

      new Compressor(file, {
        quality: quality,
        width: selectImageWidth,
        height: selectImageHeight,
        resize: 'cover',
        convertSize: 50000,
        success: (compressedResult) => {
          if (selectImageWidth === 480 || selectImageWidth === 840) {
            setSelectImageTall(compressedResult);
            setSelectImageTallPreview(URL.createObjectURL(compressedResult));
          }  else {
            setSelectImageWide(compressedResult);
            setSelectImageWidePreview(URL.createObjectURL(compressedResult));
          }
          closeSelectImage();
        }
      })
    }
  }

  return (
    <div>
      <div className={`avatar-editor ${selectImageWidth === 480 || selectImageWidth === 840 ? 'tall' : 'wide'}`}>
        <AvatarEditor 
          ref={cropRef}
          image={selectImageObject}
          width={selectImageWidth}
          height={selectImageHeight}
          border={selectImageWidth === 480 ? tallBorer : wideBorder}
          borderRadius={0}
          className={`canvas`}
        />
      </div>
      <div className="buttons-group">
        <Button
          className='button variant-1 size-big'
          onClick={handleSave}
        >
          <div className="button-title">Guardar</div>
        </Button>
      </div>
    </div>
  )
}
