import React, { useContext, useEffect, useState } from 'react'
import { ContentListsContext } from '../../../context/ContentLists'
import Input from '../../../components/form/Input';
import { Box, Button, Chip, CircularProgress, FormControl, MenuItem, OutlinedInput, Select } from '@mui/material';
import animeGenres from '../../../utils/Genres';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../context/auth/AuthContext';
import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

export default function Basic() {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const { currentAnime, setCurrentAnime } = useContext(ContentListsContext);

    const [ name, setName ] = useState(currentAnime.name);
    const [ synopsis, setSynopsis ] = useState(currentAnime.synopsis);
    const [ genres, setGenres ] = useState(currentAnime.categories);
    const [ tags, setTags ] = useState(currentAnime.tags);

    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    const handleGenres = (event) => {
        const {
            target: { value },
        } = event;
        setGenres(
            typeof value === 'string' ? value.toString(',') : value,
        );
    };

    function handleTags(value){
        if(tags.includes(value)){
            setTags(tags.filter(cat => cat !== value))
        } else {
            setTags(tags.concat(value))
        }
    };

    const uploadChanges = (e) => {
        e.preventDefault();
        setLoading(true);

        const id = params.id;
        const newAnimeData = {
            name: name,
            synopsis: synopsis,
            categories: genres,
            tags: tags,
            images: {
                tall: currentAnime.images.tall,
                wide: currentAnime.images.wide
            }
        }

        import(`../../../services/firebase/anime_${lang}`)
        .then((db) => {
            const serieRef = doc(db.animeFirestore, `animes/${id}`);
            const searchRef = doc(db.animeFirestore, `search/animes`);

            delete currentAnime.id;

            setDoc(serieRef, {
                info: arrayUnion(newAnimeData)
            }).then(() => {
                delete newAnimeData.synopsis;
                newAnimeData.id = id;
                updateDoc(searchRef, {
                    list: arrayUnion(newAnimeData)
                }).then(() => {
                    delete currentAnime.synopsis;
                    currentAnime.id = id;
                    updateDoc(searchRef, {
                        list: arrayRemove(currentAnime)
                    }).then(() => {

                        if (JSON.stringify(genres) !== JSON.stringify(currentAnime.categories)) {
                            currentAnime.categories.map((genre) => {
                                const genreRef = doc(db.animeFirestore, `genres/${genre}`);

                                if (!genres.includes(genre)) {
                                    updateDoc(genreRef, {
                                        data: arrayRemove({
                                            name: name,
                                            cover: currentAnime.images.tall,
                                            id: id,
                                            tags: tags
                                        })
                                    })
                                }
                            })

                            genres.map(async (genre) => {
                                const genreRef = doc(db.animeFirestore, `genres/${genre}`);
                                const genreSnap = await getDoc(genreRef);
                                if (!currentAnime.categories.includes(genre)) {
                                    if (genreSnap.exists()) {
                                        updateDoc(genreRef, {
                                            data: arrayUnion({
                                                name: name,
                                                cover: currentAnime.images.tall,
                                                id: id,
                                                tags: tags
                                            })
                                        })
                                    } else {
                                        setDoc(genreRef, {
                                            data: arrayUnion({
                                                name: name,
                                                cover: currentAnime.images.tall,
                                                id: id,
                                                tags: tags
                                            })
                                        })
                                    }
                                }
                            })
                        }

                        const newCurrentAnime = {
                            name: name,
                            synopsis: synopsis,
                            categories: genres,
                            tags: tags,
                            images: {
                                tall: currentAnime.images.tall,
                                wide: currentAnime.images.wide
                            }
                        }
                        setCurrentAnime(newCurrentAnime);
                        setName(newCurrentAnime.name);
                        setSynopsis(newCurrentAnime.synopsis);
                        setGenres(newCurrentAnime.categories);
                        setTags(newCurrentAnime.tags);
                        setDisabled(true);
                        setLoading(false);
                    })
                })
            })

        })
    }

    useEffect(() => {
        if (name !== currentAnime.name || synopsis !== currentAnime.synopsis || JSON.stringify(genres) !== JSON.stringify(currentAnime.categories) || tags !== currentAnime.tags) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [name, synopsis, genres, tags])
    
    return (
        <div style={{marginTop: '2rem'}} className={`anime-info ${loading && 'loading-container'}`}>
            <div className="section">
                <h2>Información básica</h2>
                <form onSubmit={uploadChanges} className='form'>
                    <Input 
                        label
                        labelText="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        variant={1}
                    />
                    <Input 
                        label
                        labelText="Sinopsis"
                        type="text-area"
                        value={synopsis}
                        onChange={(e) => setSynopsis(e.target.value)}
                        variant={1}
                    />
                    <FormControl size="small" fullWidth>
                        <label htmlFor="chip">*Géneros</label>
                        <Select
                            multiple
                            required
                            id="genres"
                            name="genres"
                            className='select'
                            value={genres}
                            onChange={handleGenres}
                            input={<OutlinedInput id="select-multiple-chip" className='chip' />}
                            renderValue={(selected) => (
                                <Box>
                                    {selected.map((value) => (
                                        <Chip className='chips' key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {animeGenres.map((e) => (
                                <MenuItem
                                    className='button variant-2'
                                    key={e.id}
                                    value={e.id}
                                    style={{gap: '.5rem'}}
                                >
                                    {e.icon}
                                    {e.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <div className="flex flow-column gap-normal">
                        <Button onClick={() => handleTags('airing')} className={`button variant-toggle ${tags.includes('airing') && 'active'}`}>
                            <div className="button-title">En emisión</div>
                            <div className="toggle"></div>
                        </Button>
                        <Button onClick={() => handleTags('sub')} className={`button variant-toggle ${tags.includes('sub') && 'active'}`}>
                            <div className="button-title">Subtitulado</div>
                            <div className="toggle"></div>
                        </Button>
                        <Button onClick={() => handleTags('dub')} className={`button variant-toggle ${tags.includes('dub') && 'active'}`}>
                            <div className="button-title">Doblado</div>
                            <div className="toggle"></div>
                        </Button>
                    </div>
                    <div className={`buttons-group ${disabled && 'hiden'}`}>
                        <Button className={`button variant-1 size-big ${loading && 'loading'}`} type='submit' disabled={disabled}>
                            <div className="button-title">Guardar</div>
                            <div className="loading">
                                <CircularProgress className='progress' />
                            </div>
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}
