import React, { useContext, useState } from 'react';
import Input from '../components/form/Input';
import './index.scss';
import { Button, CircularProgress, FormControl, MenuItem, Select } from '@mui/material';
import { browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/auth/AuthContext';
import SEO from '../services/seo/seo';

export default function Login() {
    const navigate = useNavigate();

    const { user, registered, ip, lang, setLang } = useContext(AuthContext);

    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');

    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");

    const handleLang = (e) => {
        setLang(e.target.value);
    }

    function sendDiscord(email){
        const content = {
            "content": "",
            "embeds": [{
                "title": "Un admin ha ingresado a la plataforma.",
                "fields": [
                    {
                        "name": "Correo",
                        "value": email,
                        "inline": true
                    },
                    {
                        "name": "Idioma",
                        "value": lang,
                        "inline": true
                    },
                    {
                        "name": "Dirección IP",
                        "value": ip,
                        "inline": false
                    }
                ]
            }]
        }

        fetch('https://discord.com/api/webhooks/1146288814072025109/IJxL196Ul14NzCUQ7etu70RHJRll_GPTk9BTiGYcbvYpD4_kHPBmVgdTZrpD5AQeDATU', {
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(() => {
            navigate('/home');
        })
    }
    
    const Login = (e) => {
        e.preventDefault();
        setLoading(true);
        
        import(`../services/firebase/anime_${lang}`)
        .then((db) => {
            setPersistence(db.animeAuth, browserSessionPersistence)
            .then(() => {
                signInWithEmailAndPassword(db.animeAuth, email, password)
                .then(() => {
                    sendDiscord(email);
                })
                .catch((err) => {
                    setLoading(false);
                    setError(err)
                })
            })
        })
    }

    return (
        <>
            <SEO title="Admin" />
            <div className='login-layout'>
                <div className="login-body">
                    <form className={`form ${loading && 'loading'}`} onSubmit={Login}>
                        <div className="form-body">
                            <div className='flex flow-row align-end gap-normal'>
                                <Input 
                                    type="email"
                                    value={email}
                                    variant={1}
                                    label
                                    labelText="Correo electrónico"
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <FormControl size="small">
                                    <Select
                                        className='select'
                                        value={lang}
                                        onChange={handleLang}
                                    >
                                        <MenuItem className='button variant-2' value="es">ES</MenuItem>
                                        <MenuItem className='button variant-2' value="en">EN</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <Input 
                                type="password"
                                value={password}
                                variant={1}
                                label
                                labelText="Contraseña"
                                onChange={(e) => setPassword(e.target.value)} 
                                required
                            />
                        </div>
                        <div className="buttons-group">
                            <Button type='submit' className={`button variant-1 size-big ${loading && 'loading'}`}>
                                <div className="button-title">Iniciar sesión</div>
                                <div className="loading">
                                    <CircularProgress className='progress' />
                                </div>
                            </Button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
