import { 
    AutoAwesomeOutlined,
    AutoStoriesOutlined,
    ExploreOutlined,
    FavoriteBorderRounded,
    LocalFireDepartmentOutlined,
    PetsOutlined,
    RocketLaunchOutlined,
    SentimentVerySatisfiedOutlined,
    TheaterComedyOutlined, 
    LocalDining, 
    MusicNote, 
    Explicit, 
    Female, 
    Male, 
    SportsBaseball 
} from "@mui/icons-material";

const animeGenres = [
    {
        id: 'action',
        name: 'Acción',
        icon: <LocalFireDepartmentOutlined />
    },
    {
        id: 'adventure',
        name: 'Aventura',
        icon: <ExploreOutlined />
    },
    {
        id: 'comedy',
        name: 'Comedia',
        icon: <SentimentVerySatisfiedOutlined />
    },
    {
        id: 'drama',
        name: 'Drama',
        icon: <TheaterComedyOutlined />
    },
    {
        id: 'fantasy',
        name: 'Fantasia',
        icon: <AutoAwesomeOutlined />
    },
    {
        id: 'music',
        name: 'Musical',
        icon: <MusicNote />
    },
    {
        id: 'romance',
        name: 'Romance',
        icon: <FavoriteBorderRounded />
    },
    {
        id: 'sci-fi',
        name: 'Ciencia Ficción',
        icon: <RocketLaunchOutlined />
    },
    {
        id: 'seinen',
        name: 'Seinen',
        icon: <Explicit />
    },
    {
        id: 'shojo',
        name: 'Shoujo',
        icon: <Female />
    },
    {
        id: 'shonen',
        name: 'Shounen',
        icon: <Male />
    },
    {
        id: 'slice of life',
        name: 'Recuentos de la vida',
        icon: <AutoStoriesOutlined />
    },
    {
        id: "sports",
        name: "Deportes",
        icon: <SportsBaseball />
    },
    {
        id: 'supernatural',
        name: 'Sobrenatural',
        icon: <PetsOutlined />
    },
    {
        id: 'thriller',
        name: 'thriller',
        icon: <LocalDining />
    }
]
export default animeGenres;