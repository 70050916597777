import React, { useContext, useRef, useState } from 'react'
import { ContentListsContext } from '../../../context/ContentLists'
import { Button, CircularProgress } from '@mui/material';
import { ModalsContext } from '../../../context/Modals';
import Modal from '../../../components/modals/Modal';
import SelectImage from '../../../components/modals/SelectImage';
import { AddAPhoto, ChangeCircle, ChangeCircleOutlined } from '@mui/icons-material';
import { AuthContext } from '../../../context/auth/AuthContext';
import { ref, uploadBytes } from 'firebase/storage';

export default function Visual() {
    const inputRef = useRef(null);
    const { lang } = useContext(AuthContext);
    const { currentAnime } = useContext(ContentListsContext);
    const { 
        selectImage, 
        selectImageTall, 
        selectImageWide, 
        selectImageWidePreview, 
        selectImageTallPreview, 
        openSelectImage, 
        selectImageState, 
        setSelectImageTall, 
        setSelectImageWide, 
        setSelectImageTallPreview,
        setSelectImageWidePreview,
        closeSelectImage 
    } = useContext(ModalsContext);
    
    const [ imageType, setImageType ] = useState('');

    const [ loadingTallImages, setLoadingTallImages ] = useState(false);
    const [ loadingWideImages, setLoadingWideImages ] = useState(false);

    const [ loadingTall, setLoadingTall ] = useState(false);
    const [ loadingWide, setLoadingWide ] = useState(false);
    
    const handleImageChange = (e) => {
        if (imageType === "tall") {
            openSelectImage(e.target.files[0], 480, 720);
            inputRef.current.value = "";
        } else if (imageType === "wide") {
            openSelectImage(e.target.files[0], 1200, 675);
            inputRef.current.value = "";
        }
    }

    const uploadImage = (type) => {
        import(`../../../services/firebase/anime_${lang}`)
        .then((db) => {
            const tallCover = ref(db.animeStorage, `animes/${currentAnime.id}/tall.webp`);
            const wideCover = ref(db.animeStorage, `animes/${currentAnime.id}/wide.webp`);

            
            if (type === "tall") {
                setLoadingTall(true);
                uploadBytes(tallCover, selectImageTall)
                .then(() => {
                    setLoadingTallImages(true);
                    setSelectImageTall(null);
                    setSelectImageTallPreview(null);
                    setTimeout(() => {
                        setLoadingTallImages(false);
                        setLoadingTall(false);
                    }, 100)
                })
            }

            if (type === "wide") {
                setLoadingWide(true);
                uploadBytes(wideCover, selectImageWide)
                .then(() => {
                    setLoadingWideImages(true);
                    setSelectImageWide(null);
                    setSelectImageWidePreview(null);
                    setTimeout(() => {
                        setLoadingWide(false);
                        setLoadingWideImages(false);
                    }, 100)
                })
            }
        })
    }

    return (
        <>
            <div className='visual-layout'>
                <div className="visual-body">
                    <div className="visual-data">
                        {!loadingTallImages &&
                            <div className="flex flow-row gap-big">
                                <div className="visual-data__thumbnail">
                                    <div className="visual-data__thumbnail-title">
                                        Antigua
                                    </div>
                                    <div className="thumbnail tall">
                                        <picture>
                                            <source />
                                            <img src={currentAnime.images.tall} alt="" />
                                        </picture>
                                    </div>
                                </div>
                                <div className="visual-data__thumbnail">
                                    <div className="visual-data__thumbnail-title">
                                        Nueva
                                    </div>
                                    
                                    {selectImageTallPreview ?
                                        <Button 
                                            className='thumbnail tall button with-hover'
                                            onClick={() => {
                                                setImageType('tall');
                                                inputRef.current.click();
                                            }}
                                        >
                                            <div className="hover">
                                                <div className="button-icon"><ChangeCircleOutlined /></div>
                                                <div className="button-title">Cambiar</div>
                                            </div>
                                            <div className="button-content">
                                                <div>
                                                    <picture>
                                                        <source src={selectImageTallPreview} type='image/webp' />
                                                        <img src={selectImageTallPreview} alt="" />
                                                    </picture>
                                                </div>
                                            </div>
                                        </Button>
                                        :
                                        <Button 
                                            className='thumbnail tall button'
                                            onClick={() => {
                                                setImageType('tall');
                                                inputRef.current.click();
                                            }}
                                        >
                                            <div className="button-icon"><AddAPhoto /></div>
                                            <div className="button-title">Añadir</div>
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                        <div className="visual-data__actions">
                            <Button
                                className={`button variant-1 size-big ${loadingTall && 'loading'}`}
                                disabled={selectImageTallPreview ? false : true}
                                onClick={() => uploadImage('tall')}
                            >
                                <div className="button-title">Guardar</div>
                                <div className="loading">
                                    <CircularProgress className='progress' />
                                </div>
                            </Button>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="visual-data">
                        {!loadingWideImages &&
                            <div className="flex flow-row gap-big">
                                <div className="visual-data__thumbnail">
                                    <div className="visual-data__thumbnail-title">
                                        Antigua
                                    </div>
                                    <div className="thumbnail wide">
                                        <picture>
                                            <source />
                                            <img src={currentAnime.images.wide} alt="" />
                                        </picture>
                                    </div>
                                </div>
                                <div className="visual-data__thumbnail">
                                    <div className="visual-data__thumbnail-title">
                                        Nueva
                                    </div>
                                    {selectImageWidePreview ?
                                        <Button 
                                            className='thumbnail wide button with-hover'
                                            onClick={() => {
                                                setImageType('wide');
                                                inputRef.current.click();
                                            }}
                                        >
                                            <div className="hover">
                                                <div className="button-icon"><ChangeCircleOutlined /></div>
                                                <div className="button-title">Cambiar</div>
                                            </div>
                                            <div className="button-content">
                                                <div>
                                                    <picture>
                                                        <source />
                                                        <img src={selectImageWidePreview} alt="" />
                                                    </picture>
                                                </div>
                                            </div>
                                        </Button>
                                        :
                                        <Button 
                                            className='thumbnail wide button'
                                            onClick={() => {
                                                setImageType('wide');
                                                inputRef.current.click();
                                            }}
                                        >
                                            <div className="button-icon"><AddAPhoto /></div>
                                            <div className="button-title">Añadir</div>
                                        </Button>
                                    }
                                </div>
                            </div>
                        }
                        <div className="visual-data__actions">
                            <Button
                                className={`button variant-1 size-big ${loadingWide && 'loading'}`}
                                disabled={selectImageWidePreview ? false : true}
                                onClick={() => uploadImage('wide')}
                            >
                                <div className="button-title">Guardar</div>
                                <div className="loading">
                                    <CircularProgress className='progress' />
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <input 
                type="file" 
                accept='image/*,.jpe'
                id="input-image"
                className='hide'
                ref={inputRef}
                onChange={handleImageChange}
            />
            {selectImage && 
                <Modal
                    title="Seleccionar imagen"
                    close={closeSelectImage}
                    variant="select-image"
                    visible={selectImageState}
                >
                    <SelectImage />
                </Modal>
            }
        </>
    )
}
