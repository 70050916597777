import { Button, CircularProgress, FormControl, IconButton, MenuItem, Select, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../context/auth/AuthContext';
import { arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc } from 'firebase/firestore';
import { useParams } from 'react-router-dom';
import { AddAPhoto, AddCircleOutline, ArrowBack, ChangeCircleOutlined, ContentCopy, Delete, Edit, SubdirectoryArrowRight, VideoSettings } from '@mui/icons-material';
import Modal from '../../components/modals/Modal';
import Input from '../../components/form/Input';
import { ModalsContext } from '../../context/Modals';
import SelectImage from '../../components/modals/SelectImage';
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ContentListsContext } from '../../context/ContentLists';

const Reproductors = ({ type, setReproductors, reproductors, intoModal }) => {
    const [ premium, setPremium ] = useState(reproductors ? reproductors.premium : null);
    const [ others, setOthers ] = useState(reproductors ? reproductors.others : []);

    const [ reproductor, setReproductor ] = useState('');
    const [ copy, setCopy ] = useState([]);
    const [ disabled, setDisabled ] = useState(true);

    const [ modal, setModal ] = useState(false);
    const [ modalState, setModalState ] = useState(null);
    const [ modalLink, setModalLink ] = useState('');
    const [ intoModalShow, setIntoModalShow ] = useState(false);

    const addReproductor = (e) => {
        e.preventDefault();
        if (!disabled) {
            const match = reproductor.replace(/.+\/\/|www.|\..+/g, '');

            if (match === "drive") {
                setPremium(reproductor);
                setReproductor('');

                setReproductors({
                    others: others,
                    premium: reproductor,
                    type: type
                })
            } else {
                if (others.find((e) => e === reproductor)) {
                    setReproductor('');
                    return;
                } else {
                    let reproductors = others;
                    reproductors.push(reproductor);
                    setOthers(reproductors);
                    setReproductor('');

                    setReproductors({
                        others: others,
                        premium: premium,
                        type: type
                    })
                }
            }
        }
    }

    const deleteReproductor = (link) => {
        const filtered = others.filter(url => url !== link);
        setOthers(filtered);
        setReproductor('');

        setReproductors({
            others: filtered,
            premium: premium,
            type: type
        })
    }

    function openModal(link) {
        setModal(true);
        setModalLink(link);
        if (intoModal) {
            setIntoModalShow(true);
        }
        setTimeout(() =>{
            setModalState('show');
        }, 100)
    }

    function closeModal() {
        setModalState(null);
        setTimeout(() =>{
            setModal(false);
            setModalLink('');
            if (intoModal) {
                setIntoModalShow(false);
            }
        }, 100)
    }

    useEffect(() => {
        const linkVerifier = /(?:https?):\/\/(\w+:? \w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=%&@~_|-]*))?/
       
        if (linkVerifier.test(reproductor)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [reproductor]);

    return(
        <>
            <div className={`reproductors ${intoModalShow && 'hide'}`} style={{ padding: '.75rem .75rem 0'}}>
                <div className="reproductors-header">
                    <h3>{type === "sub" && 'Subtitulado'} {type === "dub" && 'Doblado'}</h3>
                </div>
                <div className="reproductors-body">
                    <form className="reproductors-body-input" onSubmit={addReproductor}>
                        <Input 
                            placeholder
                            placeholderText={"Link del reproductor"}
                            value={reproductor}
                            onChange={(e) => setReproductor(e.target.value)}
                            variant={1}
                            required
                        />
                        <Tooltip title="Añadir link">
                            <IconButton type='submit' className='button variant-icon'>
                                <AddCircleOutline />
                            </IconButton>
                        </Tooltip>
                    </form>
                    <div className="reproductors-data">
                        {premium &&
                            <div className="reproductor">
                                <Button onClick={() => openModal(premium)} className='button outline variant-2'>
                                    <div className="button-title">
                                        {premium.replace(/.+\/\/|www.|\..+/g, '')}
                                    </div>
                                </Button>
                                <CopyToClipboard text={premium} onCopy={() => setCopy([...copy, premium])}>
                                    <Tooltip title="Copiar link">
                                        <IconButton className={`button variant-icon ${copy.includes(premium) && 'active'}`}>
                                            <ContentCopy />
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                                <Tooltip title="Eliminar link">
                                    <IconButton onClick={() => {
                                        setPremium('');
                                        setReproductors({
                                            others: others,
                                            premium: '',
                                            type: type
                                        })
                                        }} className='button variant-icon'>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        }
                        {others.map((e, i) => (
                            <div key={i} className="reproductor">
                                <Button onClick={() => openModal(e)} className='button outline variant-2'>
                                    <div className="button-title">
                                        {e.replace(/.+\/\/|www.|\..+/g, '')}
                                    </div>
                                </Button>
                                <CopyToClipboard text={e} onCopy={() => setCopy([...copy, e])}>
                                    <Tooltip title="Copiar link">
                                        <IconButton className={`button variant-icon ${copy.includes(e) && 'active'}`}>
                                            <ContentCopy />
                                        </IconButton>
                                    </Tooltip>
                                </CopyToClipboard>
                                <Tooltip title="Eliminar link">
                                    <IconButton onClick={() => deleteReproductor(e)} className='button variant-icon'>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {modal &&
            <>
                {intoModal ?
                    <div className='flex justify-center align-center flow-column' style={{ background: '#121212', width: '100%'}}>
                        <Button onClick={closeModal} className='button variant-3'>
                            <div className="button-icon"><ArrowBack /></div>
                            <div className="button-title">Volver</div>
                        </Button>
                        <div style={{ width: '100%', display: 'flex', margin: 'auto 0'}}>
                            <iframe src={modalLink} style={{ width: '100%', aspectRatio: '16 / 9'}} alt="" />
                        </div>
                    </div>
                :
                <Modal
                    visible={modalState}
                    close={closeModal}
                    variant={"video"}
                >
                    <iframe src={modalLink} alt="" />
                </Modal>
                }
            </>
            }
        </>
    )
}

const UploadEpisode = ({ episodes, season, refresh }) => {
    const inputRef = useRef(null);
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const { currentAnime } = useContext(ContentListsContext);
    const [ id, setId ] = useState('');
    const [ name, setName ] = useState('');
    const [ minutes, setMinutes ] = useState('');
    const [ seconds, setSeconds ] = useState('');
    const [ tags, setTags ] = useState([]);

    const [ subReproductors, setSubReproductors ] = useState([]);
    const [ dubReproductors, setDubReproductors ] = useState([]);

    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');

    const { 
        selectImage,
        openSelectImage,
        selectImageState,
        selectImageWide, 
        selectImageWidePreview, 
        setSelectImageWide,
        setSelectImageWidePreview,
        closeSelectImage
    } = useContext(ModalsContext);

    const handleImageChange = (e) => {
        openSelectImage(e.target.files[0], 448, 252);
        inputRef.current.value = "";
    }

    function handleTags(value){
        if(tags.includes(value)){
            setTags(tags.filter(cat => cat !== value));
            if (value === "sub") {
                setSubReproductors([]);
            } else if (value === "dub") {
                setDubReproductors([]);
            }
        } else {
            setTags(tags.concat(value))
        }
    }

    const uploadEpisode = (e) => {
        e.preventDefault();
        setLoading(true);
        import(`../../services/firebase/anime_${lang}`)
        .then((db) => {
            const episodeImage = ref(db.animeStorage, `animes/${params.id}/${season.id}/${id}.webp`);
            const episodeRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}`);
            const videoRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}/episodes/${id}`);
            const homeSection = doc(db.animeFirestore,`home/swiper_sections`);

            uploadBytes(episodeImage, selectImageWide)
            .then((response) => {
                getDownloadURL(response.ref)
                .then((url) => {
                    const duration = (minutes * 60) + (seconds * 1);

                    const episodeArray ={
                        id: id,
                        name: name,
                        duration: duration,
                        cover: url,
                        tags: tags
                    }

                    updateDoc(episodeRef, {
                        episodes_list: arrayUnion(episodeArray)
                    }).then(() => {
                        const videoArray = [];

                        if (JSON.stringify(subReproductors) !== '[]') {
                            videoArray.push(subReproductors);
                        }

                        if (JSON.stringify(dubReproductors) !== '[]') {
                            videoArray.push(dubReproductors);
                        }

                        setDoc(videoRef, {
                            videos: videoArray
                        }).then(async () => {
                            const homeData = await getDoc(homeSection);

                            if (homeData.exists()) {
                                const list = homeData.data().data.find(x => x.id.toString() === "2");
                                const newList = homeData.data().data.find(x => x.id.toString() === "2");

                                newList.content.push({
                                    cover: url,
                                    duration: duration,
                                    id: id,
                                    name: name,
                                    tags: tags,
                                    info: {
                                        season: {
                                            id: season.id,
                                            name: season.name
                                        },
                                        serie: {
                                            cover: currentAnime.images.wide,
                                            id: currentAnime.id,
                                        }
                                    }
                                })

                                if (newList.content.length >= 15) {
                                    newList.content.splice(0, 1);
                                }

                                updateDoc(homeSection, {
                                    data: arrayUnion(newList)
                                }).then(() => {
                                    updateDoc(homeSection, {
                                        data: arrayRemove(list)
                                    }).then(() => {
                                        setSelectImageWide(null);
                                        setSelectImageWidePreview(null);
                                        refresh();
                                    })
                                })
                            }
                        })
                    })              
                })
            })
        })
    }

    useEffect(() => {
        if (episodes.find(x => x.id === id)) {
            setError('already');
            setDisabled(true);
        } else {
            setError('');
        }
    }, [id]);

    useEffect(() => {
        function findDub() {
            if(tags.includes('dub')) {
                console.log('2')
                if (dubReproductors.others) {
                    console.log('2-1')
                    if (dubReproductors.others.length > 0 ) {
                        console.log('2-2-1')
                        setDisabled(false);
                    } else {
                        console.log('2-2-2')
                        setDisabled(true);
                    }
                } else {
                    console.log('2-2')
                    setDisabled(true);
                }
            }
        }

        if (name !== '' && id !== '' && minutes !== '' && seconds !== '' && JSON.stringify(tags) !== '[]') {
            if(tags.includes('sub')) {
                console.log('1')
                if (subReproductors.others) {
                    console.log('1-1')
                    if (subReproductors.others.length > 0 ) {
                        console.log('1-1-1')
                        setDisabled(false);
                        findDub();
                    } else {
                        console.log('1-1-2')
                        setDisabled(true);
                        findDub();
                    }
                } else {
                    console.log('1-2')
                    setDisabled(true);
                    findDub();
                }
            } else {
                console.log('0-1')
                setDisabled(true);

                findDub();
            }
            
        } else {
            setDisabled(true);
        }
    }, [name, id, minutes, seconds, tags, subReproductors, dubReproductors]);

    return (
        <>
            <form className={`form upload-episode ${loading && 'loading'}`} style={{gap: '.75rem', border: '0', padding: '.75rem'}} onSubmit={uploadEpisode}>
                <h2 style={{margin: 0}}>Subir episodio</h2>
                <div className='flex flow-row gap-normal'>
                    <input 
                        type="file" 
                        accept='image/*,.jpe'
                        id="input-image"
                        className='hide'
                        ref={inputRef}
                        onChange={handleImageChange}
                    />
                    <Button className='thumbnail wide button with-hover' style={{ width: 300}} onClick={() => inputRef.current.click()}>
                        {selectImageWidePreview ?
                            <>
                                <div className="hover">
                                    <div className="button-icon"><ChangeCircleOutlined /></div>
                                    <div className="button-text">Cambiar</div>
                                </div>
                                <div className="button-content">
                                    <div>
                                        <picture>
                                            <source src={selectImageWidePreview} type='image/webp' />
                                            <img src={selectImageWidePreview} alt="" />
                                        </picture>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className="button-icon"><AddAPhoto /></div>
                                <div className="button-title">Añadir</div>
                            </>
                        }
                    </Button>
                    <div className="flex flow-column justify-space-between gap-big" style={{ flex: 1}}>
                        <div className="flex flow-row gap-normal">
                            <Input 
                                label
                                labelText={`Episodio #${id}`}
                                variant={1}
                                type={"number"}
                                value={id}
                                onChange={(e) => setId(e.target.value)}
                                required
                            />
                            <Input 
                                label
                                labelText="Minutos"
                                variant={1}
                                type={"number"}
                                value={minutes}
                                onChange={(e) => setMinutes(e.target.value)}
                                required
                            />
                            <Input 
                                label
                                labelText="Segundos"
                                variant={1}
                                type={"number"}
                                value={seconds}
                                onChange={(e) => setSeconds(e.target.value)}
                                required
                            />
                        </div>
                        <div className="flex flow-row gap-normal">
                            <Button onClick={() => handleTags('sub')} className={`button variant-toggle ${tags.includes('sub') && 'active'}`} style={{flex: 1}}>
                                    <div className="button-title">Subtitulado</div>
                                    <div className="toggle"></div>
                                </Button>
                            <Button onClick={() => handleTags('dub')} className={`button variant-toggle ${tags.includes('dub') && 'active'}`} style={{flex: 1}}>
                                <div className="button-title">Doblado</div>
                                <div className="toggle"></div>
                            </Button>
                        </div>
                    </div>
                </div>
                <Input 
                    label
                    labelText="Nombre"
                    variant={1}
                    type={"text"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
                <div className='error-label' style={{ padding: '0 .5rem'}}>
                    {error === 'already' && <span>Este episodio ya existe.</span>}
                </div>
                <div className={`buttons-group fixed ${disabled && 'hiden'}`}>
                    <Button
                        className={`button variant-1 size-big ${loading && 'loading'}`}
                        type='submit'
                        disabled={disabled}
                    >
                        <div className="button-title">Subir</div>
                        <div className="loading">
                            <CircularProgress className='progress' />
                        </div>
                    </Button>
                </div>
            </form>
            
            <div className="flex flow-row gap-normal" style={{ marginBottom: '6rem'}}>
                {tags.includes('sub') &&
                    <Reproductors 
                        type={'sub'} 
                        setReproductors={setSubReproductors}
                    />
                }
                {tags.includes('dub') &&
                    <Reproductors 
                        type={'dub'} 
                        setReproductors={setDubReproductors}
                    />
                }
            </div>
            {selectImage &&
                <Modal
                    title="Seleccionar imagen"
                    close={closeSelectImage}
                    variant="select-image"
                    visible={selectImageState}
                >
                    <SelectImage />
                </Modal>
            }
        </>
    )
}

const EditInfo = ({ episodes, episode, close, season, fetchData }) => {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const [ id, setId ] = useState(episode.id);
    const [ name, setName ] = useState(episode.name);
    const [ minutes, setMinutes ] = useState(Math.floor((episode.duration % 3600) / 60));
    const [ seconds, setSeconds ] = useState(episode.duration % 60);
    const [ tags, setTags ] = useState(episode.tags);

    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');

    function handleTags(value){
        if(tags.includes(value)){
            setTags(tags.filter(cat => cat !== value));
        } else {
            setTags(tags.concat(value))
        }
    }

    const uploadEpisode = (e) => {
        e.preventDefault();
        if (!disabled) {
            setLoading(true);
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                const episodeRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}`);
                if (id !== episode.id) {
                    const oldVideoRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}/episodes/${episode.id}`);
                    const newVideoRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}/episodes/${id}`);
                } else {
                    const duration = (minutes * 60) + (seconds * 1);

                    const episodeArray = {
                        id: id,
                        name: name,
                        duration: duration,
                        cover: episode.cover,
                        tags: tags
                    }
                    updateDoc(episodeRef, {
                        episodes_list: arrayUnion(episodeArray)
                    }).then(() => {
                        updateDoc(episodeRef, {
                            episodes_list: arrayRemove(episode)
                        }).then(() => {
                            fetchData();
                            close();
                            setLoading(false);
                        })
                    })
                }
            })
        }
    }

    useEffect(() => {
        if (id === episode.id) {
            setError('');
        } else if (episodes.find(x => x.id === id)) {
            setError('already');
            setDisabled(true);
        } else {
            setError('');
        }
    }, [id]);

    useEffect(() => {
        if (name !== episode.name ||
            id !== episode.id ||
            minutes !== Math.floor((episode.duration % 3600) / 60) ||
            seconds !== episode.duration % 60 ||
            JSON.stringify(tags) !== JSON.stringify(episode.tags)) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    })

    return (
        <form className={`form ${loading && 'loading'}`} style={{gap: '.75rem'}} onSubmit={uploadEpisode}>
            <div className='flex flow-column gap-normal' style={{ padding: '.75rem'}}>
                <div className="flex flow-column justify-space-between gap-big" style={{ flex: 1}}>
                    <div className="flex flow-row gap-normal">
                        <Input 
                            label
                            labelText={`Episodio #${id}`}
                            variant={1}
                            type={"number"}
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                            required
                            readOnly
                        />
                        <Input 
                            label
                            labelText="Minutos"
                            variant={1}
                            type={"number"}
                            value={minutes}
                            onChange={(e) => setMinutes(e.target.value)}
                            required
                        />
                        <Input 
                            label
                            labelText="Segundos"
                            variant={1}
                            type={"number"}
                            value={seconds}
                            onChange={(e) => setSeconds(e.target.value)}
                            required
                        />
                    </div>
                    <div className="flex flow-row gap-normal">
                        <Button onClick={() => handleTags('sub')} className={`button variant-toggle ${tags.includes('sub') && 'active'}`} style={{flex: 1}}>
                                <div className="button-title">Subtitulado</div>
                                <div className="toggle"></div>
                            </Button>
                        <Button onClick={() => handleTags('dub')} className={`button variant-toggle ${tags.includes('dub') && 'active'}`} style={{flex: 1}}>
                            <div className="button-title">Doblado</div>
                            <div className="toggle"></div>
                        </Button>
                    </div>
                </div>
            </div>
            <div style={{ padding: '.75rem'}}>
                <Input 
                    label
                    labelText="Nombre"
                    variant={1}
                    type={"text"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className='error-label' style={{ padding: '0 .5rem'}}>
                {error === 'already' && <span>Este episodio ya existe.</span>}
            </div>
            <div className="buttons-group">
                <Button
                    className={`button variant-1 size-big ${loading && 'loading'}`}
                    type='submit'
                    disabled={disabled}
                >
                    <div className="button-title">Subir</div>
                    <div className="loading">
                        <CircularProgress className='progress' />
                    </div>
                </Button>
            </div>
        </form>
    )
}

const EditVideo = ({ episode, season, close, fetchData }) => {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const [ tags, setTags ] = useState(episode.tags);
    
    const [ subReproductorsOriginal, setSubReproductorsOriginal ] = useState(null);
    const [ dubReproductorsOriginal, setDubReproductorsOriginal ] = useState(null);
    
    const [ subReproductors, setSubReproductors ] = useState(null);
    const [ dubReproductors, setDubReproductors ] = useState(null);

    const [ disabled, setDisabled ] = useState(true);
    const [ fetching, setFetching ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    function handleTags(value){
        if(tags.includes(value)){
            setTags(tags.filter(cat => cat !== value));
            if (value === "sub") {
                setSubReproductors(null);
            } else if (value === "dub") {
                setDubReproductors(null);
            }
        } else {
            setTags(tags.concat(value))
        }
    }

    const changeVideo = (e) => {
        e.preventDefault();

        if (!disabled) {
            setLoading(true);
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                const videoRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}/episodes/${episode.id}`);

                const videosArray = [];

                if (dubReproductors) {
                    videosArray.push(dubReproductors);
                }

                if (subReproductors) {
                    videosArray.push(subReproductors);
                }

                updateDoc(videoRef, {
                    videos: videosArray
                }).then(() => {
                    if (tags !== episode.tags) {
                        const episodeRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}`);
                        
                        const episodeArray = {
                            id: episode.id,
                            name: episode.name,
                            duration: episode.duration,
                            cover: episode.cover,
                            tags: tags
                        }

                        updateDoc(episodeRef, {
                            episodes_list: arrayUnion(episodeArray)
                        }).then(() => {
                            updateDoc(episodeRef, {
                                episodes_list: arrayRemove(episode)
                            }).then(() => {
                                fetchData();
                                close();
                                setLoading(false);
                            })
                        })
                    } else {
                        fetchData();
                        close();
                        setLoading(false);
                    }
                })
            })
        }
    }

    useEffect(() => {
        if (fetching) {
            import(`../../services/firebase/anime_${lang}`)
            .then(async (db) => {
                const videoRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}/episodes/${episode.id}`);
                const videosSnap = await getDoc(videoRef);

                const data = videosSnap.data().videos;

                if (data.find(x => x.type === 'sub')) {
                    setSubReproductorsOriginal(Array.from([data.find(x => x.type === 'sub')]));
                    setSubReproductors(data.find(x => x.type === 'sub'));
                }

                if (data.find(x => x.type === 'dub')) {
                    setDubReproductorsOriginal(Array.from([data.find(x => x.type === 'dub')]));
                    setDubReproductors(data.find(x => x.type === 'dub'));
                }

                setFetching(false);
            })
        }
    }, []);

    useEffect(() => {
        if (subReproductorsOriginal) {
            if (JSON.stringify(subReproductorsOriginal[0]) !== JSON.stringify(subReproductors)) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            if (subReproductors) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [subReproductors]);

    useEffect(() => {
        if (dubReproductorsOriginal) {
            if (JSON.stringify(dubReproductorsOriginal[0]) !== JSON.stringify(dubReproductors)) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            if (dubReproductors) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }, [dubReproductors])

    return (
        <div className={`form ${loading && 'loading'}`} style={{gap: '.75rem'}}>
            <div className='flex flow-row gap-normal'>
                <div className="flex flow-column justify-space-between gap-big" style={{ flex: 1}}>
                    <div className="flex flow-row gap-normal">
                        <Button onClick={() => handleTags('sub')} className={`button variant-toggle ${tags.includes('sub') && 'active'}`} style={{flex: 1}}>
                                <div className="button-title">Subtitulado</div>
                                <div className="toggle"></div>
                            </Button>
                        <Button onClick={() => handleTags('dub')} className={`button variant-toggle ${tags.includes('dub') && 'active'}`} style={{flex: 1}}>
                            <div className="button-title">Doblado</div>
                            <div className="toggle"></div>
                        </Button>
                    </div>
                </div>
            </div>
            {fetching ?
                <div className='flex justify-center'>
                    <CircularProgress style={{color: '#FFF'}} />
                </div>
                :
                <div className="flex flow-row gap-normal">
                    {tags.includes('sub') &&
                        <Reproductors 
                            type={'sub'} 
                            setReproductors={setSubReproductors}
                            reproductors={subReproductors}
                            intoModal
                        />
                    }
                    {tags.includes('dub') &&
                        <Reproductors 
                            type={'dub'} 
                            setReproductors={setDubReproductors}
                            reproductors={dubReproductors}
                            intoModal
                        />
                    }
                </div>
            }
            <div className="buttons-group">
                <Button
                    className={`button variant-1 size-big ${loading && 'loading'}`}
                    onClick={changeVideo}
                    disabled={disabled}
                >
                    <div className="button-title">Subir</div>
                    <div className="loading">
                        <CircularProgress className='progress' />
                    </div>
                </Button>
            </div>
        </div>
    )
}

const EditImage = ({ episode, season, close, fetchData }) => {
    const inputRef = useRef(null);
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const { 
        selectImage,
        openSelectImage,
        selectImageWide, 
        selectImageWidePreview, 
        setSelectImageWide,
        setSelectImageWidePreview,
        closeSelectImage
    } = useContext(ModalsContext);

    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    const changeImage = (e) => {
        e.preventDefault();
        if (!disabled) {
            setLoading(true);
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                const episodeImage = ref(db.animeStorage, `animes/${params.id}/${season.id}/${episode.id}.webp`);
                uploadBytes(episodeImage, selectImageWide)
                .then(() => {
                    setSelectImageWide(null);
                    setSelectImageWidePreview(null);
                    fetchData();
                    close();
                })
            })
        }
    }

    const handleImageChange = (e) => {
        openSelectImage(e.target.files[0], 320, 180);
        inputRef.current.value = "";
    }

    useEffect(() => {
        if (selectImageWidePreview) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    })

    return (
        <>
            <form className={`form ${selectImage && 'hide'} ${loading && 'loading'}`} style={{gap: '.75rem'}} onSubmit={changeImage}>
                <div className='flex flow-column gap-normal' style={{ padding: '.75rem .75rem 0'}}>
                    <input 
                        type="file" 
                        accept='image/*,.jpe'
                        id="input-image"
                        className='hide'
                        ref={inputRef}
                        onChange={handleImageChange}
                    />
                    <div className='flex flow-row gap-normal justify-space-between'>
                        <div className='flex flow-column gap-normal' style={{ width: '50%'}}>
                            <h3>Antiguo</h3>
                            <div style={{width: '100%', marginBottom: '1rem'}} className='thumbnail wide button with-hover'>
                                <div className="button-content">
                                    <div>
                                        <picture>
                                            <source src={episode.cover} type='image/webp' />
                                            <img style={{ width: '100%'}} src={episode.cover} alt="" />
                                        </picture>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex flow-column gap-normal' style={{ width: '50%'}}>
                            <h3>Nuevo</h3>
                            <Button style={{width: '100%', marginBottom: '1rem'}} className='thumbnail wide button with-hover' onClick={() => inputRef.current.click()}>
                                {selectImageWidePreview ?
                                    <>
                                        <div className="hover">
                                            <div className="button-icon"><ChangeCircleOutlined /></div>
                                            <div className="button-text">Cambiar</div>
                                        </div>
                                        <div className="button-content">
                                            <div>
                                                <picture>
                                                    <source src={selectImageWidePreview} type='image/webp' />
                                                    <img style={{ width: '100%'}} src={selectImageWidePreview} alt="" />
                                                </picture>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="button-icon"><AddAPhoto /></div>
                                        <div className="button-title">Añadir</div>
                                    </>
                                }
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="buttons-group">
                    <Button
                        className={`button variant-1 size-big ${loading && 'loading'}`}
                        type='submit'
                        disabled={disabled}
                    >
                        <div className="button-title">Subir</div>
                        <div className="loading">
                            <CircularProgress className='progress' />
                        </div>
                    </Button>
                </div>
            </form>
            {selectImage &&
                <div className='flex justify-center align-center flow-column' style={{ background: '#121212', width: '100%'}}>
                    <Button onClick={closeSelectImage} className='button variant-3'>
                        <div className="button-icon"><ArrowBack /></div>
                        <div className="button-title">Volver</div>
                    </Button>
                    <div style={{ width: '100%', margin: 'auto 0'}}>
                        <SelectImage />
                    </div>
                </div>
            }
        </>
    )
}

const EpisodeData = ({ data, season, episodes, fetchData }) => {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const [ modal, setModal ] = useState(false);
    const [ modalState, setModalState ] = useState(null);
    const [ modalType, setModalType ] = useState('');

    const [ loading, setLoading ] = useState(false);

    function openModal(type) {
        setModal(true);
        setModalType(type);
        setTimeout(() => {
            setModalState('show');
        }, 100)
    }

    function closeModal() {
        setModalState(null);
        setTimeout(() => {
            setModal(false);
            setModalType('');
        }, 100)
    }

    const Duration = () => {
        const hours = Math.floor(data.duration / 3600);
        const minutes = Math.floor((data.duration % 3600) / 60);
        const seconds = data.duration % 60;

        if (hours > 0) {
            return `${hours}h ${minutes}m ${seconds}s`;
        } else {
            return `${minutes}m ${seconds}s`;
        }
    }

    const DeleteEpisode = () => {
        const deleteEpisode = (e) => {
            e.preventDefault();
            setLoading(true);
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                const episodeImage = ref(db.animeStorage, `animes/${params.id}/${season.id}/${data.id}.webp`);
                const episodeRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}`);
                const videoRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}/episodes/${data.id}`);
                
                deleteObject(episodeImage)
                .then(() => {
                    deleteDoc(videoRef)
                    .then(() => {
                        updateDoc(episodeRef, {
                            episodes_list: arrayRemove(data)
                        })
                        .then(() => {
                            fetchData();
                            closeModal();
                            setLoading(false);
                        })
                    })
                })
            })
        }

        return (
            <form className={`form ${loading && 'loading'}`} style={{gap: '.75rem'}} onSubmit={deleteEpisode}>
                <div style={{ padding: '1rem'}}>
                    Estas seguro de que deseas eliminar: <br /><br />
                    <span>{`${season.name}`}</span><br />
                    <div className='error-label flex align-center'>
                        <SubdirectoryArrowRight />
                        <span style={{ marginTop: '.25rem'}}>{`T${season.id} E${data.id} - ${data.name}`}</span>
                    </div>
                </div>
                <div className="buttons-group flex gap-normal">
                    <Button className='button variant-2 size-big outline' type='button' onClick={closeModal}>
                        <div className="button-title">Cancelar</div>
                    </Button>
                    <Button
                        className={`button variant-1 size-big ${loading && 'loading'}`}
                        type='submit'
                    >
                        <div className="button-title">Eliminar</div>
                        <div className="loading">
                            <CircularProgress className='progress' />
                        </div>
                    </Button>
                </div>
            </form>
        )
    }

    return (
        <>
            <div className='episode-edit-button' onDoubleClick={() => openModal('edit')}>
                <Button className='thumbnail wide button with-hover' onClick={() => openModal('edit-image')}>
                    <div className="hover">
                        <div className="button-icon"><ChangeCircleOutlined /></div>
                        <div className="button-text">Cambiar</div>
                    </div>
                    <div className="button-content">
                        <div>
                            <picture>
                                <source src={data.cover} type='image/webp' />
                                <img src={data.cover} alt="" />
                            </picture>
                        </div>
                    </div>
                </Button>
                <div className='data'>
                    <div className="text">
                        <div>{data.id}</div>
                        <div>{data.name}</div>
                    </div>
                    <div className="duration"><Duration /></div>
                    <div className='tags'>
                        {data.tags.map((tag, i) => (
                            <span key={i}>{tag}</span>
                        ))}
                    </div>
                </div>
                <div>
                    <Tooltip title="Editar Reproductores" onClick={() => openModal('edit-video')}>
                        <IconButton className='button variant-icon'>
                            <VideoSettings />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Editar información" onClick={() => openModal('edit')}>
                        <IconButton className='button variant-icon'>
                            <Edit />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Eliminar Episodio" onClick={() => openModal('delete')}>
                        <IconButton className='button variant-icon'>
                            <Delete />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {modal &&
                <Modal
                    title={modalType === 'delete' ? '¿Seguro?' : `Editar - Episodio ${data.id}`}
                    visible={modalState}
                    close={closeModal}
                >
                    {modalType === 'edit' && <EditInfo episode={data} close={closeModal} episodes={episodes} season={season} fetchData={fetchData} />}
                    {modalType === 'edit-image' && <EditImage close={closeModal} episode={data} season={season} fetchData={fetchData} />}
                    {modalType === 'edit-video' && <EditVideo close={closeModal} episode={data} season={season} fetchData={fetchData}  />}
                    {modalType === 'delete' && <DeleteEpisode />}
                </Modal>
            }
        </>
    )
}

const Episodes = ({ season }) => {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const [ episodes, setEpisodes ] = useState([]);

    const [ uploadEpisode, setUploadEpisode ] = useState(false);
    const [ loading, setLoading ] = useState(true);

    function fetchData(){
        setLoading(true);
        import(`../../services/firebase/anime_${lang}`)
        .then(async (db) => {
            const episodesRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season.id}`);
            const episodesSnap = await getDoc(episodesRef);

            const data = episodesSnap.data().episodes_list;
            data.sort(function(a, b) {
                return a.id - b.id
            })

            setEpisodes(data);
            setUploadEpisode(false);
            setLoading(false);
        })
    }

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            if (loading) {
                fetchData();
            }
        }

        return () => ignore = true;
    }, []);

    if (loading) {
        return (
            <div className='flex justify-center' style={{marginTop: '1rem'}}>
                <CircularProgress className='circular-progress' />
            </div>
        )
    }

    return (
        <div style={{marginTop: '1rem'}}>
            {episodes.length < 1 || uploadEpisode ?
                <>
                    {uploadEpisode && 
                        <Button onClick={() => setUploadEpisode(false)} className='button variant-3'>
                            <div className="button-icon"><ArrowBack /></div>
                            <div className="button-title">Volver</div>
                        </Button>
                    }
                    <UploadEpisode episodes={episodes} season={season} refresh={fetchData} />
                </>
            :
                <div className='flex flow-column gap-normal'>
                    <div className='flex flex-flow row gap-normal' style={{ marginBottom: '1rem'}}>
                        <Button className='button outline variant-3' onClick={() => setUploadEpisode(true)}>
                            <div className="button-icon"><AddCircleOutline /></div>
                            <div className="button-title">Subir episodio</div>
                        </Button>
                    </div>
                    {episodes.map((e, i) => (
                        <EpisodeData data={e} fetchData={fetchData} season={season} episodes={episodes} key={i} />
                    ))}
                </div>
            }
        </div>
    )
}

const EditSeason = ({ data, reFetch, close }) => {
    const [ season, setSeason ] = useState(data.id);
    const [ MAL, setMAL ] = useState(data.MAL);
    const [ name, setName ] = useState(data.name);
    const [ rating, setRating ] = useState(data.rating);
    
    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');

    const handleRating = (e) => {
        setRating(e.target.value);
    }

    const editSeason = (e) => {
        e.preventDefault();
    }

    useEffect(() => {
        if (name !== data.name || rating !== data.rating || MAL !== data.MAL || season !== data.id) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [name, rating, MAL, season])

    return (
        <form className={`form ${loading && 'loading'}`} onSubmit={editSeason}>
            <div className='flex flex-flow gap-normal' style={{ padding: '.5rem .5rem 0'}}>
                <Input 
                    label
                    labelText={`Temporada #${season}`}
                    type="number"
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                    variant={1}
                    required
                />
                <Input 
                    label
                    labelText={`My Anime List ID`}
                    type="number"
                    value={MAL}
                    onChange={(e) => setMAL(e.target.value)}
                    variant={1}
                    required
                />
                <FormControl size='small' style={{ maxWidth: '50%'}} fullWidth>
                    <label htmlFor="rating" style={{marginBottom: '.2rem'}}>*Rating</label>
                    <Select className='select'
                        value={rating}
                        onChange={handleRating}
                    >
                        <MenuItem className='button variant-2' value="G">G - Todas las edades</MenuItem>
                        <MenuItem className='button variant-2' value="PG">PG - Niños</MenuItem>
                        <MenuItem className='button variant-2' value="PG-13">PG-13 - De 13 o mayores</MenuItem>
                        <MenuItem className='button variant-2' value="R-17+">R-17+ - 17 años con supervisión (Violencia/Obscenidades)</MenuItem>
                        <MenuItem className='button variant-2' value="R+">R+ - Desnudez leve</MenuItem>
                        <MenuItem className='button variant-2' value="Rx">Rx - Hentai</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='flex flex-flow gap-normal' style={{ padding: '.5rem .5rem 0'}}>
                <Input 
                    label
                    labelText={`Nombre temporada`}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant={1}
                    required
                />
            </div>
            <div className='error-label' style={{ padding: '0 .5rem'}}>
                {error === 'already' && <span>Esta temporada ya existe.</span>}
            </div>
            <div className="buttons-group">
                <Button
                    className={`button variant-1 size-big ${loading && 'loading'}`}
                    type='submit'
                    disabled={disabled}
                >
                    <div className="button-title">Guardar</div>
                    <div className="loading">
                        <CircularProgress className='progress' />
                    </div>
                </Button>
            </div>
        </form>
    )
}

const AddSeason = ({ seasons, reFetch, close }) => {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const [ season, setSeason ] = useState('');
    const [ MAL, setMAL ] = useState('');
    const [ name, setName ] = useState('');
    const [ rating, setRating ] = useState('');

    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');

    const handleRating = (e) => {
        setRating(e.target.value);
    }

    const uploadSeason = (e) => {
        e.preventDefault();
        if (error === 'already') {
            return;
        }

        import(`../../services/firebase/anime_${lang}`)
        .then((db) => {
            const seasonRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season}`);
            const seasonsRef = doc(db.animeFirestore, `animes/${params.id}/seasons/info`);

            setLoading(true);

            updateDoc(seasonsRef, {
                data: arrayUnion({MAL: MAL, id: season, name: name, rating: rating})
            }).then(() => {
                setDoc(seasonRef, {
                    episodes_list: []
                }).then(() => {
                    close();
                    reFetch();
                })
            })
        })
    }

    useEffect(() => {
        if (seasons.find(x => x.id === season)) {
            setError('already');
            setDisabled(true);
        } else {
            setError('');
        }
    }, [season])

    useEffect(() => {
        if (name !== "" && rating !== "" && MAL !== "" && season !== "") {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [name, rating, MAL, season])

    return (
        <form className={`form ${loading && 'loading'}`} onSubmit={uploadSeason}>
            <div className='flex flex-flow gap-normal' style={{ padding: '.5rem .5rem 0'}}>
                <Input 
                    label
                    labelText={`Temporada #${season}`}
                    type="number"
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                    variant={1}
                    required
                />
                <Input 
                    label
                    labelText={`My Anime List ID`}
                    type="number"
                    value={MAL}
                    onChange={(e) => setMAL(e.target.value)}
                    variant={1}
                    required
                />
                <FormControl size='small' style={{ maxWidth: '50%'}} fullWidth>
                    <label htmlFor="rating" style={{marginBottom: '.2rem'}}>*Rating</label>
                    <Select className='select'
                        value={rating}
                        onChange={handleRating}
                    >
                        <MenuItem className='button variant-2' value="G">G - Todas las edades</MenuItem>
                        <MenuItem className='button variant-2' value="PG">PG - Niños</MenuItem>
                        <MenuItem className='button variant-2' value="PG-13">PG-13 - De 13 o mayores</MenuItem>
                        <MenuItem className='button variant-2' value="R-17+">R-17+ - 17 años con supervisión (Violencia/Obscenidades)</MenuItem>
                        <MenuItem className='button variant-2' value="R+">R+ - Desnudez leve</MenuItem>
                        <MenuItem className='button variant-2' value="Rx">Rx - Hentai</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='flex flex-flow gap-normal' style={{ padding: '.5rem .5rem 0'}}>
                <Input 
                    label
                    labelText={`Nombre temporada`}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant={1}
                    required
                />
            </div>
            <div className='error-label' style={{ padding: '0 .5rem'}}>
                {error === 'already' && <span>Esta temporada ya existe.</span>}
            </div>
            <div className="buttons-group">
                <Button
                    className={`button variant-1 size-big ${loading && 'loading'}`}
                    type='submit'
                    disabled={disabled}
                >
                    <div className="button-title">Subir</div>
                    <div className="loading">
                        <CircularProgress className='progress' />
                    </div>
                </Button>
            </div>
        </form>
    )
}

export default function Seasons() {
    const params = useParams();
    const { lang } = useContext(AuthContext);
    const [ season, setSeason ] = useState('0');
    const [ seasons, setSeasons ] = useState([]);
    const [ currentSeason, setCurrentSeason ] = useState([]);

    const [ seasonsLoading, setSeasonsLoading ] = useState(true);
    const [ seasonsFetched, setSeasonsFetched ] = useState(false);

    const [ modal, setModal ] = useState(false);
    const [ modalType, setModalType ] = useState('');
    const [ modalState, setModalState ] = useState(null);

    const handleChangeSeason = (e) => {
        setSeason(e.target.value);
        setCurrentSeason(seasons.find(x => x.id === e.target.value));
        setSeasonsLoading(true);
        setTimeout(() => {
            setSeasonsLoading(false);
        }, 100);
    }

    function openModal(type) {
        setModal(true);
        setModalType(type);
        setTimeout(() =>{
            setModalState('show');
        }, 100)
    }

    function closeModal() {
        setModalState(null);
        setTimeout(() =>{
            setModalType('');
            setModal(false);
        }, 100)
    }

    function FetchData() {
        setSeasonsLoading(true);
        import(`../../services/firebase/anime_${lang}`)
        .then(async (db) => {
            const seasonsRef = doc(db.animeFirestore, `animes/${params.id}/seasons/info`);
            const seasonsSnap = await getDoc(seasonsRef);

            const data = seasonsSnap.data().data;
            data.sort(function(a, b) {
                return a.id - b.id
            })

            setSeasons(data);

            setSeasonsLoading(false);
        })
    }

    const deleteSeason = () => {
        setSeasonsLoading(true);
        import(`../../services/firebase/anime_${lang}`)
        .then(async (db) => {
            const seasonsRef = doc(db.animeFirestore, `animes/${params.id}/seasons/info`);

            updateDoc(seasonsRef, {
                data: arrayRemove(seasons.find(x => x.id.toString() === season))
            })
            .then(async () => {
                const seasonEpisodes = query(collection(db.animeFirestore, `animes/${params.id}/seasons/${season}/episodes`));
                const seasonEpisodesSnap = await getDocs(seasonEpisodes);

                seasonEpisodesSnap.forEach((data) => {
                    const episodeRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season}/episodes/${data.id}`);
                    deleteDoc(episodeRef);
                })

                const seasonRef = doc(db.animeFirestore, `animes/${params.id}/seasons/${season}`);
                deleteDoc(seasonRef)
                .then(() => {
                    setSeason('0');
                    setCurrentSeason([]);
                    FetchData();
                })
            })
        })
    }

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            if (seasonsFetched === false) {
                setSeasonsFetched(true);
                if (seasons.length < 1) {
                    FetchData();
                } else {
                    setSeasonsLoading(false);
                }
            }
        }

        return () => ignore = true;
    }, []);

    if (seasonsLoading) {
        return (
            <div className='flex justify-center'>
                <CircularProgress className='circular-progress' />
            </div>
        )
    }

    return (
        <>
            <div className='seasons'>
                <div className="seasons-header">
                    <FormControl size='small' fullWidth>
                        <Select 
                            className='select'
                            value={season}
                            onChange={handleChangeSeason}
                        >
                            <MenuItem className='button variant-2' value="0">Seleccionar temporada</MenuItem>
                            {seasons.map((e, i) => (
                                <MenuItem 
                                    key={i}
                                    className='button variant-2'
                                    value={e.id}
                                >
                                    {`[T${e.id}] ${e.name}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Tooltip title="Añadir temporada">
                        <IconButton onClick={() => openModal('add-season')} className='button variant-icon'>
                            <AddCircleOutline />
                        </IconButton>
                    </Tooltip>
                    {JSON.stringify(currentSeason) !== '[]' &&
                        <>
                            <Tooltip title="Editar temporada">
                                <IconButton onClick={() => openModal('edit-season')} className='button variant-icon'>
                                    <Edit />
                                </IconButton>
                            </Tooltip>
                            
                            <Tooltip title="Eliminar temporada (doble click)">
                                <IconButton onDoubleClick={deleteSeason} className='button variant-icon'>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                        </>
                    }
                </div>
                <div className="seasons-body">
                    {JSON.stringify(currentSeason) !== '[]' && <Episodes season={currentSeason} reFetch={FetchData} />}
                </div>
            </div>
            {modal &&
                <Modal
                    title={modalType === 'add-season' && 'Subir temporada' || modalType === "edit-season" && 'Editar temporada'}
                    visible={modalState}
                    close={closeModal}
                >
                    {modalType === 'add-season' && <AddSeason close={closeModal} reFetch={FetchData} seasons={seasons} />}
                    {modalType === 'edit-season' && <EditSeason close={closeModal} reFetch={FetchData} data={currentSeason} />}
                </Modal>
            }
        </>
    )
}
