import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../context/auth/AuthContext'
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';
import { Button, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { AddCircleOutline, ArrowBack, ChangeCircleOutlined, Check, Delete, Edit, PendingActions } from '@mui/icons-material';
import Input from '../../components/form/Input';
import { ModalsContext } from '../../context/Modals';
import SelectImage from '../../components/modals/SelectImage';
import Modal from '../../components/modals/Modal';
import Compressor from 'compressorjs';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { ContentListsContext } from '../../context/ContentLists';
import SearchIcon from '../../assets/images/Search';

const BannerContainer = ({ data, setFetched }) => {
  const { lang } = useContext(AuthContext);
  const [ loading, setLoading ] = useState(false);

  function deleteBanner() {
    setLoading(true);
    import(`../../services/firebase/anime_${lang}`)
      .then(async (db) => {
        const dataRef = doc(db.animeFirestore, `home/swiper`);
        updateDoc(dataRef, {
          data: arrayRemove(data)
        })
        .then(() => {
          setFetched(false);
        })
      })
  }

  return(
    <div className={`banner-layout__container ${loading && 'loading'}`}>
      <div className="background">
        <img src={data.images.pc} alt="" />
      </div>
      <div className="action-buttons">
        <Tooltip title="Cambiar fondo">
          <IconButton className='IconButton'>
            <div className="button-icon"><Edit /></div>
          </IconButton>
        </Tooltip>
        <Tooltip title="Eliminar Banner (Doble click)">
          <Button className='button variant-danger' onDoubleClick={deleteBanner}>
            <div className="button-icon"><Delete /></div>
          </Button>
        </Tooltip>
      </div>
      <div className="banner-layout__container-body">
        <Button className="thumbnail button thumbnail-mv with-hover">
          <div className="hover">
              <div className="button-icon"><ChangeCircleOutlined /></div>
              <div className="button-text">Cambiar</div>
          </div>
          <div className="button-content">
              <div>
                  <picture>
                      <source src={data.images.mv} type='image/webp' />
                      <img src={data.images.mv} alt="" />
                  </picture>
              </div>
          </div>
        </Button>
        <div className="data flex flow-column gap-normal">
          <Button className='thumbnail with-hover button thumbnail-logo'>
            <div className="hover">
                <div className="button-icon"><ChangeCircleOutlined /></div>
                <div className="button-text">Cambiar</div>
            </div>
            <div className="button-content">
                <div>
                    <picture>
                        <source src={data.images.logo} type='image/webp' />
                        <img src={data.images.logo} alt="" />
                    </picture>
                </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

const UploadBanner = ({ setFetched, close, deleteData }) => {
  const { lang } = useContext(AuthContext);
  const { animes, setAnimes } = useContext(ContentListsContext);

  const inputRef = useRef(null);
  const [ id, setId ] = useState('');
  const [ name, setName ] = useState('');
  const [ season, setSeason ] = useState('');
  const [ episode, setEpisode ] = useState('');
  const [ logo, setLogo ] = useState(null);
  const [ logoPreview, setLogoPreview ] = useState(null);
  const [ imageType, setImageType ] = useState('');

  const [ disabled, setDisabled ] = useState(true);
  const [ loading, setLoading ] = useState(false);

  const [ loadingImages, setLoadingImages ] = useState(false);
  const [ existImages, setExistImages ] = useState(null);
  const [ imagePc, setImagePc ] = useState('');
  const [ imageMv, setImageMv ] = useState('');

  const [ searchTerm, setSearchTerm ] = useState('');
  const [ searching, setSearching ] = useState(false);
  const [ filteredSearchAnimes, setFilteredSearchAnimes ] = useState(null);

  const { 
    selectImage, 
    selectImageTall, 
    selectImageTallPreview,
    selectImageWide, 
    selectImageWidePreview,
    openSelectImage, 
    selectImageState, 
    closeSelectImage 
  } = useContext(ModalsContext);

  const handleImageChange = (e) => {
    if (imageType === "tall") {
      openSelectImage(e.target.files[0], 840, 1260);
      inputRef.current.value = "";
    } else if (imageType === "wide") {
      openSelectImage(e.target.files[0], 1920, 1080);
      inputRef.current.value = "";
    } else if (imageType === "logo") {
      const file = new File([e.target.files[0]], "image", { type: 'image/webp'});

      new Compressor(file, {
        quality: .8,
        maxWidth: 800,
        success: (compressedResult) => {
          setLogo(compressedResult);
          setLogoPreview(URL.createObjectURL(compressedResult))
        }
      })
    }
  }

  const handleUpload = (e) => {
    e.preventDefault();
    if (!disabled) {
      setLoading(true);
      import(`../../services/firebase/anime_${lang}`)
      .then(async (db) => {
        const serieRef = doc(db.animeFirestore, `animes/${id}`);
        const serieSnap = await getDoc(serieRef);

        if (serieSnap.exists()) {
          const pcRef = ref(db.animeStorage, `home/swiper/${id}-${season}/pc.webp`);
          const mvRef = ref(db.animeStorage, `home/swiper/${id}-${season}/mv.webp`);
          const logoRef = ref(db.animeStorage, `home/swiper/${id}-${season}/logo.webp`);

          function uploadAll(logoImage, mvImage, pcImage) {
            const serieData = serieSnap.data().info[0];
  
            const dataRef = doc(db.animeFirestore, `home/swiper`);

            let bannerArray = {
              id: `${id}-${season}`,
              name: serieData.name,
              synopsis: serieData.synopsis,
              tags: serieData.tags,
              images: {
                logo: logoImage,
                mv: mvImage,
                pc: pcImage,
              }
            }

            if (episode !== "") {
              bannerArray.episode = episode
            }

            updateDoc(dataRef, {
              data: arrayUnion(bannerArray)
            }).then(() => {
              if (deleteData.length >= 6) {
                updateDoc(dataRef, {
                  data: arrayRemove(deleteData[deleteData.length - 1])
                }).then(() => {
                  setFetched(false);
                  close();
                })
              } else {
                setFetched(false);
                close();
              }
            })
          }

          if (existImages) {
            uploadAll(logoPreview, imageMv, imagePc);
          } else {
            uploadBytes(pcRef, selectImageWide)
            .then((response) => {
              getDownloadURL(response.ref)
              .then((url) => {
                const pcImage = url;
                uploadBytes(mvRef, selectImageTall)
                .then((response) => {
                  getDownloadURL(response.ref)
                  .then((url) => {
                    const mvImage = url;
                    uploadBytes(logoRef, logo)
                    .then((response) => {
                      getDownloadURL(response.ref)
                      .then((url) => {
                        const logoImage = url;
                        uploadAll(logoImage, mvImage, pcImage);
                      })
                    })
                  })
                })
              })
            })
          }
        }
      })
    }
  }

  useEffect(() => {
    if (id !== "" && season !== "") {
      if (logo && selectImageTall && selectImageWide) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [id, season, logo, selectImageTall, selectImageWide]);

  useEffect(() => {
    setSearching(true);

    function getSearchData() {
      if (animes.length > 0) {
        setFilteredSearchAnimes(
          animes.filter(
            (x) => 
            x.name.toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
        setSearching(false);
      } else {
        import(`../../services/firebase/anime_${lang}`)
        .then(async (db) => {
            const searchRef = doc(db.animeFirestore, `search/animes`);
            const searchSnap = await getDoc(searchRef);

            const data = searchSnap.data().list;
            setAnimes(data);
            
            setFilteredSearchAnimes(
                data.filter(
                    (x) =>
                    x.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setSearching(false);
        })
      }
    }

    if (searchTerm === "" || !searchTerm) {
      setSearching(false);
      setFilteredSearchAnimes(null);
      return;
    }

    const delayDebouncefn = setTimeout(() => {
      getSearchData();
    }, 400);

    return () => clearTimeout(delayDebouncefn);
  }, [searchTerm]);

  useEffect(() => {
    if (id !== '' && season !== '') {
      setLoadingImages(true);
      import(`../../services/firebase/anime_${lang}`)
      .then(async (db) => {
        const pcRef = ref(db.animeStorage, `home/swiper/${id}-${season}/pc.webp`);
        const mvRef = ref(db.animeStorage, `home/swiper/${id}-${season}/mv.webp`);
        const logoRef = ref(db.animeStorage, `home/swiper/${id}-${season}/logo.webp`);

        getDownloadURL(pcRef)
        .then((pcUrl) => {
          getDownloadURL(mvRef)
          .then((mvUrl) => {
            getDownloadURL(logoRef)
            .then((logoUrl) => {
              setLogoPreview(logoUrl);
              setImagePc(pcUrl);
              setImageMv(mvUrl);
              setExistImages(true);
              setLoadingImages(false);
              setDisabled(false);
            })
            .catch(() => {
              setLoadingImages(false);
              setExistImages(false);
            })
          })
          .catch(() => {
            setLoadingImages(false);
            setExistImages(false);
          })
        })
        .catch(() => {
          setLoadingImages(false);
          setExistImages(false);
        })
      })
    } else {
      setLoadingImages(false);
    }
  }, [id, season]);

  return (
    <>
      <div className="flex gap-big" style={{ width: '100%', flexFlow: 'row wrap'}}>
        <div className='banner-layout__container' style={{ minWidth: 240, aspectRatio: 'inherit'}}>
          <input 
            type="file" 
            accept='image/*,.jpe'
            id="input-image"
            className='hide'
            ref={inputRef}
            onChange={handleImageChange}
          />
          <form className={`form ${loading && 'loading'}`} onSubmit={handleUpload}>
            <div className="flex gap-normal" style={{ flexFlow: 'row wrap', columnGap: '1.5rem'}}>
              <div style={{ flex: 1, position: 'relative'}}>
                {filteredSearchAnimes && 
                  <div className="search-results">
                    {filteredSearchAnimes.length > 0 ?
                      <>
                        {filteredSearchAnimes.map((data, i) => (
                          <Button 
                            className={`button variant-3 ${id === data.id && 'active'}`} 
                            key={i}
                            style={{
                                overflow: 'hidden',
                                borderRadius: 2
                            }}
                            onClick={() => {
                              setId(data.id);
                              setName(data.name);
                              setFilteredSearchAnimes(null);
                              setSearchTerm('');
                            }}
                          >
                            <picture 
                              style={{ 
                                position: 'absolute',
                                zIndex: 0,
                                left: 0,
                                width: '100%',
                                opacity: .2
                              }}>
                              <source src={data.images.wide} />
                              <img style={{ width: '100%'}} src={data.images.wide} alt="" />
                            </picture>
                            {id === data.id && <div className="button-icon" style={{ position: 'relative'}}><Check /></div>}
                            <div className="button-title" style={{ position: 'relative'}}>{data.name}</div>
                          </Button>
                        ))}
                      </>
                      :
                      <h2 style={{ margin: '1rem auto 0', textAlign: 'center'}}>Sin resultados.</h2>
                    }
                  </div>
                }
                <Input 
                  placeholder
                  placeholderText={"Busca tu anime"}
                  type={"search"}
                  value={searchTerm}
                  variant={"search"}
                  icon={searching ? <CircularProgress className='input-icon' /> : <SearchIcon className="input-icon" />}
                  autoFocus
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            <div className='flex flow-row gap-normal align-end'>
              {name && <h2 style={{ flex: 1 }}>{name}</h2>}
              <div className="number-inputs">
                <Input 
                  label
                  labelText="*Temporada"
                  variant={1}
                  value={season}
                  type={"number"}
                  onChange={(e) => setSeason(e.target.value)}
                  required
                />
                <Input 
                  label
                  labelText="Episodio"
                  variant={1}
                  value={episode}
                  type={"number"}
                  onChange={(e) => setEpisode(e.target.value)}
                />
              </div>
            </div>
            {loadingImages &&
            <div className="progress flex justify-center">
              <CircularProgress className='circular-progress normal' />
            </div>
            }
            {existImages === false &&
              <div className="flex flow-column gap-normal">
                <Button
                  type='button'
                  className={`button variant-4 warning ${selectImageTall && 'active'}`} 
                  onClick={() => {
                      setImageType('tall');
                      inputRef.current.click();
                  }}
                >
                  <div className="button-title">Fondo para celulares</div>
                  <div className="button-icon">
                    {selectImageTall ?
                      <Check />
                      :
                      <PendingActions />
                    }
                  </div>
                </Button>
                <Button 
                  type='button'
                  className={`button variant-4 warning ${selectImageWide && 'active'}`} 
                  onClick={() => {
                    setImageType('wide');
                    inputRef.current.click();
                  }}
                >
                  <div className="button-title">Fondo para computadores</div>
                  <div className="button-icon">
                    {selectImageWide ?
                      <Check />
                      :
                      <PendingActions />
                    }
                  </div>
                </Button>
                <Button 
                  type='button'
                  className={`button variant-4 warning ${logo && 'active'}`} 
                  onClick={() => {
                    setImageType('logo');
                    inputRef.current.click();
                  }}
                >
                  <div className="button-title">Logo del anime</div>
                  <div className="button-icon">
                    {logo ?
                      <Check />
                      :
                      <PendingActions />
                    }
                  </div>
                </Button>
              </div>
            }
            <div className="buttons-group">
              <Button 
                disabled={disabled} 
                className={`button variant-1 size-big ${loading && 'loading'}`} 
                type='submit'
              >
                <div className="button-title">¡Subir!</div>
                <div className="loading">
                  <CircularProgress className='progress' />
                </div>
              </Button>
            </div>
          </form>
        </div>
        <div className='banner-layout__container'>
          <div className="background">
            <img src={existImages ? imagePc : selectImageWidePreview} alt="" />
          </div>
          <div className="banner-layout__container-body">
            <div className="thumbnail button thumbnail-mv with-hover">
              <div className="button-content">
                  <div>
                      <picture>
                          <source src={existImages ? imageMv : selectImageTallPreview} type='image/webp' />
                          <img src={existImages ? imageMv : selectImageTallPreview} alt="" />
                      </picture>
                  </div>
              </div>
            </div>
            <div className="data flex flow-column gap-normal">
              <div className='thumbnail with-hover button thumbnail-logo'>
                <div className="button-content">
                    <div>
                        <picture>
                            <source src={logoPreview} type='image/webp' />
                            <img src={logoPreview} alt="" />
                        </picture>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectImage &&
        <Modal
          title="Seleccionar imagen"
          close={closeSelectImage}
          variant={"select-image"}
          visible={selectImageState}
        >
          <SelectImage />
        </Modal>
      }
    </>
    
  )
}

export default function Banner() {
  const { lang } = useContext(AuthContext);
  const [ data, setData ] = useState([]);

  const [ uploadBanner, setUploadBanner ] = useState(false);

  const [ fetched, setFetched ] = useState(false);

  function getData() {
    if (!fetched) {
      import(`../../services/firebase/anime_${lang}`)
      .then(async (db) => {
        const dataRef = doc(db.animeFirestore, `home/swiper`);
        const dataSnap = await getDoc(dataRef);

        const data = dataSnap.data().data;

        setData(data.reverse());
        setFetched(true);
      })
    }
  }

  useEffect(() => {
    if (!fetched) {
      getData();
    }
  }, [fetched]);

  return (
    <div className='banner-layout'>
      <div className='flex justify-center'>
        {uploadBanner ?
          <Button className='button variant-1 size-big gap-normal flex' onClick={() => setUploadBanner(false)}>
            <div className="button-icon"><ArrowBack /></div>
            <div className="button-title">Volver</div>
          </Button>
          :
          <Button className='button variant-1 size-big gap-normal flex' onClick={() => setUploadBanner(true)}>
            <div className="button-icon"><AddCircleOutline /></div>
            <div className="button-title">Subir Otro banner</div>
          </Button>
        }
      </div>
      <div className="banner-layout__body">
        {uploadBanner ?
          <UploadBanner
            deleteData={data}
            setFetched={setFetched} 
            close={() => setUploadBanner(false)} 
          />
        :
          <>
            {fetched &&
              <>
                {data.map((doc, i) => (
                  <BannerContainer key={i} setFetched={setFetched} data={doc} />
                ))}
              </>
            }
          </>
        }
      </div>
    </div>
  )
}
