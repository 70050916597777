import React, { useEffect, useState } from 'react';
import SEO from "../../services/seo/seo"
import { Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import './Home.scss';
import { NavLink, Outlet } from 'react-router-dom';

export default function Home() {
  return (
    <>
      <SEO title="Inicio" />
      <div className='home'>
        <div className='home-header'>
          <h1>Inicio</h1>
        </div>
        <div className="nav-tabs" style={{ padding: '.75rem'}}>
          <Button 
            className='button'
            component={NavLink}
            to="/home/banner"  
          >
            <div className='button-icon'><Edit /></div>
            <div className="button-title">Editar cartel principal</div>
          </Button>
          <Button 
            className='button'
            component={NavLink}
            to="/home/sections"  
          >
            <div className='button-icon'><Edit /></div>
            <div className="button-title">Editar Secciones</div>
          </Button>
        </div>
        <div className="home-body">
          <Outlet />
        </div>
      </div>
    </>
  )
}
